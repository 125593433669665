import { generateUUID } from '../../../utils/logging';

type ConversionUuid = {
  uuid: string;
  expiredAt: number;
};

// UUID の有効期限 (1時間)
export const UUID_EXPIRY_TIME = 60 * 60 * 1000;

export interface IStorage {
  getItem: (key: string) => string | null;
  setItem: (key: string, value: string) => void;
  removeItem: (key: string) => void;
}

export const getConversionUuid = (storage: IStorage = localStorage) => {
  const stored = storage.getItem('conversionUuid');
  const conversionUuid: ConversionUuid = stored
    ? JSON.parse(stored)
    : { uuid: '', expiredAt: 0 };

  if (!conversionUuid.uuid || new Date(conversionUuid.expiredAt) < new Date()) {
    // 存在しないまたは期限切れの場合は、両方新たに生成して保存する
    const newConversionUuid = {
      uuid: generateUUID(),
      expiredAt: Date.now() + UUID_EXPIRY_TIME,
    };
    storage.setItem('conversionUuid', JSON.stringify(newConversionUuid));
    return newConversionUuid.uuid;
  } else {
    // すでに存在していて、期限が切れていない場合は、期限を更新して保存する
    const updatedConversionUuid = {
      ...conversionUuid,
      expiredAt: Date.now() + UUID_EXPIRY_TIME,
    };
    storage.setItem('conversionUuid', JSON.stringify(updatedConversionUuid));
    return updatedConversionUuid.uuid;
  }
};

export const removeConversionUuid = (storage: IStorage = localStorage) => {
  storage.removeItem('conversionUuid');
};
