/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';

export const styles = {
  expiredReserve: css`
    text-align: center;
    padding: 24px;
  `,
  reservedContainer: css`
    padding: 0 24px;
  `,
  reserveChangeTitle: css`
    font-size: 20px;
    font-weight: bold;
    padding: 24px 0 0;
  `,
  maxWidth: css`
    max-width: 700px;
    margin: 0 auto;
  `,
  minutesRequiredFields: css`
    margin: 16px auto;
    padding: 0 16px;
  `,
};
