/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';
import { Course } from '../../../@interfaces/course';
import Description from '../../shop-courses/components/Description';

type DescriptionContentProps = {
  course: Course | undefined;
  primaryColor: string;
};

/**
 * コースの説明文を表示
 * @returns
 */
export const DescriptionContent = ({
  course,
  primaryColor,
}: DescriptionContentProps): JSX.Element | null => {
  if (!course?.description) {
    return null;
  }
  return (
    <div
      css={css`
        padding: 0 16px;
      `}
    >
      <Description
        description={course?.description}
        primaryColor={primaryColor}
      />
    </div>
  );
};
