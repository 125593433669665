import axios from 'axios';
import { useEffect } from 'react';
import { PublicConversionFunnelRequestData } from '../../../@interfaces/insight/conversion-funnel-api';
import { API_END_POINT } from '../../../api/api';
import { getConversionUuid } from '../utils/conversionUuid';

export const useConversionFunnel = (
  workspaceUid: string,
  shopUid: string,
  inflowSource: string | null
) => {
  useEffect(() => {
    try {
      const data: PublicConversionFunnelRequestData = {
        conversionUuid: getConversionUuid(),
        inflowSource,
      };
      void axios.post(
        `${API_END_POINT}/public/ws/${workspaceUid}/shops/${shopUid}/conversion-funnel`,
        data
      );
    } catch (e) {
      console.error(e);
    }
  }, [inflowSource, shopUid, workspaceUid]);
};
