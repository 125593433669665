/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';
import React, { useMemo } from 'react';
import { CustomerLastInputOverview } from '../../../../@interfaces/customer-last-input';
import {
  AnyField,
  publicFields,
} from '../../../../core/types/reservation-form-types';

const styles = {
  lastInputsHeader: css({
    fontSize: '14px',
    fontWeight: 'bold',
    paddingBottom: '8px',
  }),
  lastInputs: css({
    backgroundColor: '#fff',
    borderRadius: '10px',
    padding: '12px 12px 2px 12px',
    marginBottom: '20px',
  }),
  lastInputLabel: css({
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
  }),
  lastInputTitle: css({
    fontSize: '14px',
    fontWeight: 'bold',
  }),
  lastInputField: css({
    width: '100%',
    fontSize: '16px',
    borderRadius: '10px',
    marginBottom: '10px',
  }),
  lastInputsCancelButton: css({
    fontSize: '13px',
    border: 'none',
    padding: '4px 8px',
    background: 'none',
    cursor: 'pointer',
    '&:hover': {
      opacity: 0.7,
    },
  }),
};

type CustomerLastInputFieldsProps = {
  fields: AnyField[];
  customerLastInput?: CustomerLastInputOverview;
  primaryColor: string;
  onCancelLastInput: () => void;
};

export const CustomerLastInputFields = ({
  fields,
  customerLastInput,
  primaryColor,
  onCancelLastInput,
}: CustomerLastInputFieldsProps) => {
  const { nameField, emailField, telField } = useMemo(() => {
    const pubFields = publicFields(fields);
    const nameField = pubFields.find((f) => f.type === 'name');
    const emailField = pubFields.find((f) => f.type === 'email');
    const telField = pubFields.find((f) => f.type === 'tel');
    return {
      nameField,
      emailField,
      telField,
    };
  }, [fields]);

  const buildLastInputField = (
    field: AnyField | undefined,
    value: string | undefined
  ) => {
    if (!field || !value) {
      return null;
    }
    return (
      <div>
        <div css={styles.lastInputLabel}>
          <div css={styles.lastInputTitle}>{field.name}</div>
        </div>
        <div css={styles.lastInputField}>{value}</div>
      </div>
    );
  };

  return (
    <>
      <div css={styles.lastInputsHeader}>
        前回予約時の情報
        <button
          type="button"
          css={styles.lastInputsCancelButton}
          style={{ color: primaryColor }}
          onClick={onCancelLastInput}
        >
          変更する
        </button>
      </div>
      <div css={styles.lastInputs}>
        {buildLastInputField(nameField, customerLastInput?.name)}
        {buildLastInputField(telField, customerLastInput?.tel)}
        {buildLastInputField(emailField, customerLastInput?.email)}
      </div>
    </>
  );
};
