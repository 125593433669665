/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';
import { ChangeEvent } from 'react';
import {
  CheckboxField,
  Option,
} from '../../../../../core/types/reservation-form-types';
import CheckboxActive from '../../../../../image/checkbox_active.svg';
import { FieldErrorMessage } from '../FieldErrorMessage';
import { BaseFormFieldProps } from './BaseFormField';
import { FieldDescription } from './FieldDescription';
import { FieldLabel } from './FieldLabel';
import { buildFieldValidation } from './input-helpers';
import { styles as inputStyles } from './input-styles';
import { styles } from './radio-checkbox-styles';

type CheckboxFormFieldProps = BaseFormFieldProps & {
  field: CheckboxField;
};
export function CheckboxFormField(props: CheckboxFormFieldProps) {
  const {
    field,
    fieldResponse,
    validationContext,
    onChangeValue,
    primaryColor,
  } = props;
  const { errors, register, trigger } = validationContext;
  const fieldValidation = buildFieldValidation(field);

  const customStyles = {
    customCheckbox: css({
      position: 'relative',
      display: 'inline-block',
      verticalAlign: 'middle',
      width: '20px',
      height: '20px',
      border: '2px solid #A2AAB7',
      borderRadius: '6px',
      '&.checked': {
        borderColor: primaryColor,
        background: primaryColor,
        '&::after': {
          content: `url(${CheckboxActive})`,
          position: 'absolute',
          top: '35%',
          left: '50%',
          transform: 'translate(-50%,-50%)',
          width: '14px',
          height: '14px',
          background: primaryColor,
        },
      },
    }),
  };

  const isChecked = (option: Option) => {
    const { values } = fieldResponse;
    return (
      values.includes(option.text) ||
      values.find(
        (v) =>
          typeof v !== 'string' &&
          option.uid !== undefined &&
          option.uid === v.uid
      ) !== undefined
    );
  };

  const changeMultiValue = (textOrUidValue: string) => {
    const newValues = [...fieldResponse.values];
    let index = fieldResponse.values.indexOf(textOrUidValue);
    if (index === -1) {
      index = fieldResponse.values.findIndex(
        (v) => typeof v !== 'string' && textOrUidValue === v.uid
      );
    }
    if (index === -1) {
      const option = field.options.find(
        (o) => o.uid && o.uid === textOrUidValue
      );
      if (option) {
        newValues.push({ uid: option.uid });
      } else {
        newValues.push(textOrUidValue);
      }
    } else {
      newValues.splice(index, 1);
    }
    onChangeValue?.(field, newValues);
  };

  const handleChangeCheckBox = async (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    changeMultiValue(value);
    await trigger(field.uid);
  };

  const buildOption = (option: Option, index: number) => {
    const id = `${field.uid}_${index}`;
    return (
      <div key={id} style={{ marginTop: index === 0 ? '0' : '8px' }}>
        <input
          id={id}
          name={field.uid}
          type="checkbox"
          value={option.uid || option.text}
          checked={isChecked(option)}
          onChange={handleChangeCheckBox}
          ref={register(fieldValidation)}
          style={{ display: 'none' }}
        />
        <label htmlFor={id} css={styles.customLabel}>
          <span
            css={customStyles.customCheckbox}
            className={isChecked(option) ? 'checked' : ''}
          ></span>
          <span css={styles.customLabelText}>{option.text}</span>
        </label>
      </div>
    );
  };

  const buildOptions = () => {
    return field.options.map((option, index) => buildOption(option, index));
  };

  const fieldError = errors[field.uid];

  return (
    <div css={[styles.fieldPaper, fieldError && styles.inputErrorField]}>
      <div
        css={inputStyles.inputLabel}
        style={{ marginTop: '0', borderBottom: '.5px solid #8898AA' }}
      >
        <div css={styles.questionText}>
          <FieldLabel field={field} />
        </div>
        <FieldErrorMessage field={field} errors={errors} />
      </div>
      <FieldDescription description={field.description} />
      <div style={{ padding: '20px 12px 0' }}>{buildOptions()}</div>
    </div>
  );
}
