import { useCallback, useState } from 'react';

type StorageType = 'local' | 'session';

// Hook
export default function useStorage<T>(
  key: string,
  initialValue: T,
  type: StorageType = 'local'
): [T, (val: T) => void] {
  // State to store our value
  // Pass initial state function to useState so logic is only executed once
  const [storedValue, setStoredValue] = useState<T>(() => {
    try {
      // Get from local storage by key
      const item = getStorage(type).getItem(key);
      // Parse stored json or if none return initialValue
      return item ? JSON.parse(item) : initialValue;
    } catch (error) {
      // If error also return initialValue
      console.log(error);
      return initialValue;
    }
  });

  // Return a wrapped version of useState's setter function that ...
  // ... persists the new value to localStorage.
  const setValue = useCallback(
    (value: T) => {
      try {
        // Allow value to be a function so we have same API as useState
        const valueToStore = value;
        // Save state
        setStoredValue(valueToStore);
        // Save to local storage
        getStorage(type).setItem(key, JSON.stringify(valueToStore));
      } catch (error) {
        // A more advanced implementation would handle the error case
        console.log(error);
      }
    },
    [key, type]
  );

  return [storedValue, setValue];
}

function getStorage(type: StorageType) {
  return type == 'local' ? window.localStorage : window.sessionStorage;
}
