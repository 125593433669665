/**
 * 標準のドメイン（開発環境含む）
 */
const STANDARD_DOMAINS = ['localhost', 'res.locaop.jp', 'stg-res.locaop.jp'];

/**
 * 独自ドメインが設定されている場合、ブラウザのホスト名と一致しているかどうかを判定します。
 * @param domain 設定されたカスタムドメイン
 * @returns ドメインが問題なければtrue
 */
export const validDomain = (domain: string | null | undefined) => {
  if (process.env.REACT_APP_SKIP_DOMAIN_CHECK === 'true') {
    return true;
  }
  if (STANDARD_DOMAINS.includes(window.location.hostname)) {
    return true;
  }
  return domain === window.location.hostname;
};

type DomainErrorProps = {
  domain: string | null | undefined;
};

/**
 * ドメインエラー時のエラー表示UI
 */
export const DomainError: React.VFC<DomainErrorProps> = (props) => {
  const { domain } = props;
  return (
    <div>
      ドメインが一致しません。(期待するドメイン:{' '}
      {domain || process.env.REACT_APP_EXPECTED_DOMAIN}, 実際のドメイン:{' '}
      {window.location.hostname})
    </div>
  );
};
