import axios from 'axios';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import CancelFormPage from './features/cancel/routes/CancelFormPage';
import FormPage from './features/form/routes/FormPage';
import LineCallbackPage from './features/line/routes/LineCallbackPage';
import LineLoginPage from './features/line/routes/LineLoginPage';
import FormFieldsPreviewPage from './features/preview/routes/FormFieldsPreviewPage';
import ReservationTablePreviewPage from './features/preview/routes/ReservationTablePreviewPage';
import ChangeDateTimePage from './features/shop-course/routes/ChangeDateTimePage';
import ShopCoursePage from './features/shop-course/routes/ShopCoursePage';
import ShopCoursesPage from './features/shop-courses/routes/ShopCoursesPage';
import WorkspacePage from './features/workspace/routes/WorkspacePage';
import ReservationPage from './pages/ReservationPage';
import { setupLoggingRequestInterceptor } from './utils/logging';
import { setupWidgetMode } from './utils/widget';

// Axiosのエラーハンドラを初期化
const defaultErrorMessage = '通信時にエラーが発生しました。';
axios.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    if (
      error?.response?.status &&
      error.response.status >= 400 &&
      error.response.status < 500
    ) {
      alert(
        `${
          error.response.data.message ||
          error.response.data ||
          defaultErrorMessage
        }`
      );
    }
    return Promise.reject(error);
  }
);

setupLoggingRequestInterceptor();
setupWidgetMode();

function App() {
  return (
    <Router>
      <Switch>
        <Route path="/a/:workspaceUid/" component={WorkspacePage} exact />
        <Route
          path="/a/:workspaceUid/shops/:shopUid"
          component={ShopCoursesPage}
          exact
        />
        <Route
          path="/a/:workspaceUid/shops/:shopUid/courses/:courseUid"
          exact
          render={() => {
            const searchParams = new URL(window.location.href).searchParams;
            const reservationKeyParam = searchParams.get('reservationKey');
            if (reservationKeyParam) {
              // reservationKeyパラメータが存在した場合は時間変更画面を表示
              return <ChangeDateTimePage />;
            } else {
              // 予約フォームを表示
              return <ShopCoursePage />;
            }
          }}
        />
        <Route
          path="/a/:workspaceUid/shops/:shopUid/courses/:courseUid/:action(form|thanks)"
          component={FormPage}
          exact
        />
        <Route
          path="/a/:workspaceUid/shops/:shopUid/courses/:courseUid/cancel"
          component={CancelFormPage}
          exact
        />
        <Route path="/a/:workspaceUid/line" component={LineLoginPage} exact />
        <Route
          path="/a/:workspaceUid/line/callback"
          component={LineCallbackPage}
          exact
        />
        <Route
          path="/a/:workspaceUid/shops/:shopUid/courses/:courseUid/reservations/:reservationKey"
          component={ReservationPage}
          exact
        />
        {/* 管理画面からのプレビュー用 */}
        <Route
          path="/preview/form-fields"
          component={FormFieldsPreviewPage}
          exact
        />
        <Route
          path="/preview/reservation-table"
          component={ReservationTablePreviewPage}
          exact
        />
      </Switch>
    </Router>
  );
}

export default App;
