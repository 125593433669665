import axios, { AxiosResponse } from 'axios';
import { useEffect, useState } from 'react';
import { ResponseBody } from '../../../frontend-api/src/handlers/public/public-line-login-channel-handler';
import { API_END_POINT } from './api';

export default function useLineLoginChannel(workspaceUid: string) {
  const [clientId, setClientId] = useState<string>();
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setLoading(true);
    axios
      .get(`${API_END_POINT}/public/ws/${workspaceUid}/line/login-channel`)
      .then((response: AxiosResponse<ResponseBody>) => {
        setClientId(response.data.clientId);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [workspaceUid]);
  return {
    clientId,
    isLoadingShops: loading,
  };
}
