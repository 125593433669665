import dayjs, { Dayjs } from 'dayjs';
import 'dayjs/locale/ja';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
dayjs.extend(timezone);
dayjs.extend(utc);
dayjs.tz.setDefault('Asia/Tokyo');

export type IDate = {
  year: number;
  month: number;
  date: number;
};

export type DateRange = {
  start: IDate;
  end: IDate;
};

export type OptionalDateRange = {
  start?: IDate;
  end?: IDate;
};

export type Time = {
  hour: number;
  minute: number;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const isTime = (value: any): value is Time => {
  return !!(
    typeof value === 'object' &&
    typeof value?.hour === 'number' &&
    typeof value?.minute === 'number'
  );
};

export type DateTime = {
  date: IDate;
  time: Time;
};

// 9:00 -> 540の表現
export type TimeValue = number;

export type TimeRange = {
  start: Time;
  end: Time;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const isTimeRange = (value: any): value is TimeRange => {
  return (
    typeof value === 'object' && isTime(value?.start) && isTime(value?.end)
  );
};

export const isOverlappingOfTimeRanges = (
  timeRange1: TimeRange,
  timeRange2: TimeRange
): boolean => {
  const start1 = toTimeValue(timeRange1.start);
  const end1 = toTimeValue(timeRange1.end);

  const start2 = toTimeValue(timeRange2.start);
  const end2 = toTimeValue(timeRange2.end);

  return start2 < end1 && start1 < end2;
};

export const isValidTimeRange = (timeRange: TimeRange): boolean => {
  return toTimeValue(timeRange.start) < toTimeValue(timeRange.end);
};

export type TimeRangeCapacity = TimeRange & {
  capacity?: Capacity;
};

export type Slot = {
  timeRange: TimeRange;
};

export type Capacity = {
  total: number;
  // 予約上限としては空いているが、その時間開始の予約を受け付けない場合にtrue
  startNotAllowed?: boolean;
};

export type SlotCapacity = {
  slot: Slot;
  capacity: Capacity;
};

export type Reserved = {
  total: number; // 全コース合計のトータル
  courseTotal: number; // 対象コースのカウント数
  canReserve: boolean; // 予約可能かどうかの判定
};

export type ResourceCapacity = {
  total: number;
};

export type ReservedSlotCapacity = SlotCapacity & {
  reserved: Reserved;
  resourceCapacity?: ResourceCapacity;
};

export type ReservedReservation = {
  reservationId: number;
  courseId: number;
  date: IDate;
  time: Time;
  total: number;
  minutesRequired?: number;
  resourceId?: number;
};

export type DateSlotCapacity = {
  date: IDate;
  slots: SlotCapacity[];
};

export type DateReservedSlotCapacity = {
  date: IDate;
  slots: ReservedSlotCapacity[];
};

export type ReservationCapacity = {
  dateRange: DateRange;
  dates: DateSlotCapacity[];
};

export type ReservationTable = {
  dateRange: DateRange;
  dates: DateReservedSlotCapacity[];
};

export type Sunday = 'Sunday';
export type Monday = 'Monday';
export type Tuesday = 'Tuesday';
export type Wednesday = 'Wednesday';
export type Thursday = 'Thursday';
export type Friday = 'Friday';
export type Saturday = 'Saturday';

export type DayOfWeek =
  | Sunday
  | Monday
  | Tuesday
  | Wednesday
  | Thursday
  | Friday
  | Saturday;

export type WeeklyCondition = {
  type: 'weekly';
  dayOfWeeks: DayOfWeek[];
  holiday?: boolean;
};

export type MonthlyCondition = {
  type: 'monthly';
  dates: number[];
};

export type AlwaysCondition = {
  type: 'always';
};

export type CustomWeeklyCondition = {
  type: 'custom-weekly';
  dayOfWeeks: CustomDayOfWeek[];
};

export type CustomDayOfWeek = {
  dayOfWeek: DayOfWeek;
  target: WeekNumber;
};

export type WeekNumber = number;

export type ScheduleCondition =
  | CustomWeeklyCondition
  | WeeklyCondition
  | MonthlyCondition
  | AlwaysCondition;

/**
 * YOYAKU-20でcapacityはtimeRanges配下に重複して持つようにした
 * マイグレーション措置としてcapacityは残しており、timeRanges側にcapacityが存在しなければ、
 * Schedule直下のcapacityを使用する
 */
export type Schedule = {
  condition: ScheduleCondition;
  capacity?: Capacity;
  timeRanges: TimeRangeCapacity[];
};

export type RegularRule = {
  type: 'regular';
  unit: number;
  dateRange: OptionalDateRange;
  schedules: Schedule[];
};

export type HolidayRule = {
  type: 'holiday';
  dateRange: DateRange;
};

export type SlotRule = RegularRule | HolidayRule;

export type HolidayChecker = (date: IDate) => boolean;

export type BaseSlotSetting = {
  rules: SlotRule[];
  isHoliday: HolidayChecker;
};

export type SlotSetting = BaseSlotSetting & {
  courseId: number;
  minutesRequired?: number;
};

/**
 *
 * @param day 0-6 */
export function toDayOfWeek(day: number): DayOfWeek {
  if (day == 0) {
    return 'Sunday';
  } else if (day == 1) {
    return 'Monday';
  } else if (day == 2) {
    return 'Tuesday';
  } else if (day == 3) {
    return 'Wednesday';
  } else if (day == 4) {
    return 'Thursday';
  } else if (day == 5) {
    return 'Friday';
  } else if (day == 6) {
    return 'Saturday';
  } else {
    throw `Invalid dayOfWeek value: value=${day}`;
  }
}

export type DateFormat = `${number}-${number}-${number}`;
export type TimeFormat = `${number}:${number}`;
export type DateTimeFormat = `${DateFormat} ${TimeFormat}`;

export function createDateTime(dateTimeString: DateTimeFormat): DateTime {
  const [date, time] = dateTimeString.split(' ');
  return {
    date: createDate(date as DateFormat),
    time: createTime(time as TimeFormat),
  };
}

export function createDateTimeByDayjs(dateTime: Dayjs): DateTime {
  return createDateTime(dateTime.format('YYYY-MM-DD HH:mm') as DateTimeFormat);
}

export function createDate(dateString: DateFormat): IDate {
  const [year, month, date] = dateString.split('-');
  return {
    year: parseInt(year),
    month: parseInt(month),
    date: parseInt(date),
  };
}

export function createDateRange(
  startDateString: DateFormat,
  endDateString: DateFormat
): DateRange {
  const start = createDate(startDateString);
  const end = createDate(endDateString);
  return {
    start,
    end,
  };
}

export function createShowMonthRange(reservationDate: DateFormat): DateRange {
  const startDate = dayjs(reservationDate);
  const endDate = startDate.add(1, 'month').date(0);

  const start = {
    year: startDate.year(),
    month: startDate.month() + 1,
    date: 1,
  };

  const end = {
    year: endDate.year(),
    month: endDate.month() + 1,
    date: endDate.date(),
  };

  return {
    start,
    end,
  };
}

export function isTimeFormat(timeString: string): timeString is TimeFormat {
  const [hour, minute] = timeString.split(':');
  const h = parseInt(hour);
  const m = parseInt(minute);
  return !isNaN(h) && h >= 0 && h <= 30 && !isNaN(m) && m >= 0 && m <= 59;
}

export function createTime(timeString: TimeFormat): Time {
  const [hour, minute] = timeString.split(':');
  return {
    hour: parseInt(hour),
    minute: parseInt(minute),
  };
}

export function createTimeRange(
  startTimeString: TimeFormat,
  endTimeString: TimeFormat
): TimeRange {
  const start = createTime(startTimeString);
  const end = createTime(endTimeString);
  return {
    start,
    end,
  };
}

export function toDayjs(date: IDate): Dayjs {
  return dayjs
    .tz(new Date(), 'Asia/Tokyo')
    .year(date.year)
    .month(date.month == 0 ? 12 : date.month - 1)
    .date(date.date)
    .startOf('date');
}

export function toDayjsByDateTime(dateTime: DateTime): Dayjs {
  const { date, time } = dateTime;
  return toDayjs(date).hour(time.hour).minute(time.minute).startOf('minute');
}

export function toDate(day: Dayjs): IDate {
  return {
    year: day.year(),
    month: day.month() + 1,
    date: day.date(),
  };
}

export function toDateStringByDayjs(day: Dayjs): DateFormat {
  return day.format('YYYY-MM-DD') as DateFormat;
}

export function toDateStringByDate(day: IDate): DateFormat {
  return `${zeroPadding(String(day.year), 4)}-${zeroPadding(
    String(day.month),
    2
  )}-${zeroPadding(String(day.date), 2)}` as DateFormat;
}

export function toJapaneseDateStringByDayjs(day: Dayjs): string {
  return day.locale('ja').format('YYYY年M月D日(dd)');
}

export function toJapaneseDateTimeStringByDateTime(dateTime: DateTime): string {
  return toJapaneseDateTimeStringByDayjs(toDayjsByDateTime(dateTime));
}

export function toJapaneseDateTimeStringByDayjs(day: Dayjs): string {
  return day.locale('ja').format('YYYY年M月D日(dd) HH:mm');
}

export function toJapaneseDateStringByDate(day: IDate): string {
  const dayjsDate = toDayjs(day);
  return toJapaneseDateStringByDayjs(dayjsDate);
}

export function toDateTimeString(date: IDate, time: Time): DateTimeFormat {
  return `${toDateStringByDate(date)} ${toTimeStringByTime(time)}`;
}

export function zeroPadding(str: string, digit: number) {
  const paddingLength = digit - str.length;
  if (paddingLength <= 0) {
    return str;
  }
  return `${'0'.repeat(paddingLength)}${str}`;
}

export function toTimeStringByTime(time: Time): TimeFormat {
  return `${time.hour || 0}:${zeroPadding(
    time.minute?.toString() || '0',
    2
  )}` as TimeFormat;
}

export function toTimeStringByTimeNumber(timeNumber: number): TimeFormat {
  return toTimeStringByTime(toTimeByTimeNumber(timeNumber));
}

export function toTimeByTimeNumber(timeNumber: number): Time {
  return {
    hour: Math.floor(timeNumber / 60),
    minute: timeNumber % 60,
  };
}

export function toDates(range: DateRange): IDate[] {
  const { start, end } = range;
  const startDay = toDayjs(start);
  const endDay = toDayjs(end);

  const dates: IDate[] = [];
  let currentDay = startDay;
  while (currentDay.isAfter(endDay, 'date') == false) {
    const date = toDate(currentDay);
    dates.push(date);
    currentDay = currentDay.add(1, 'd');
  }
  return dates;
}

export function range(from: number, to: number): number[] {
  const result = [];
  for (let i = from; i <= to; i++) {
    result.push(i);
  }
  return result;
}

const toDateValue = (date: IDate): number => {
  return date.year * 10000 + date.month * 100 + date.date;
};

export function includesDate(
  range: OptionalDateRange | undefined,
  date: IDate
): boolean {
  if (!range) {
    return true;
  } else if (!range.start && range.end) {
    return toDateValue(date) <= toDateValue(range.end);
  } else if (range.start && !range.end) {
    return toDateValue(range.start) <= toDateValue(date);
  } else if (range.start && range.end) {
    const dateValue = toDateValue(date);
    return (
      dateValue <= toDateValue(range.end) &&
      toDateValue(range.start) <= dateValue
    );
  } else {
    return true;
  }
}

export function matchSchedule(
  condition: ScheduleCondition,
  date: IDate,
  isHoliday: HolidayChecker
): boolean {
  if (condition.type == 'weekly') {
    return matchInDayOfWeeks(condition, date, isHoliday);
  } else if (condition.type == 'monthly') {
    return matchInMonthly(condition, date);
  } else if (condition.type == 'custom-weekly') {
    return matchInCustomWeekly(condition, date);
  } else if (condition.type == 'always') {
    return true;
  } else {
    return false;
  }
}

export function matchInCustomWeekly(
  condition: CustomWeeklyCondition,
  date: IDate
): boolean {
  const targetDate = toDayjs(date);
  const week = Math.ceil(targetDate.date() / 7);
  return condition.dayOfWeeks.some((data) => {
    return (
      data.target == week &&
      data.dayOfWeek.includes(toDayOfWeek(targetDate.day()))
    );
  });
}

export function matchInDayOfWeeks(
  condition: WeeklyCondition,
  date: IDate,
  isHoliday: HolidayChecker
): boolean {
  const { dayOfWeeks, holiday } = condition;
  const targetDate = toDayjs(date);
  const matchHoliday = holiday ? isHoliday(date) : false;
  return dayOfWeeks.includes(toDayOfWeek(targetDate.day())) || matchHoliday;
}

export function matchInMonthly(
  condition: MonthlyCondition,
  date: IDate
): boolean {
  const { dates } = condition;
  return dates.find((d) => d == date.date) != undefined;
}

export function toTimeValue(time: Time): TimeValue {
  return time.hour * 60 + time.minute;
}

export function toTimeByTimeValue(timeValue: TimeValue): Time {
  return {
    hour: Math.floor(timeValue / 60),
    minute: timeValue % 60,
  };
}

export function uniqueTimes(times: Time[]) {
  return [...new Set(times.map(toTimeValue))]
    .sort((a, b) => (a < b ? -1 : 1))
    .map(toTimeByTimeValue);
}

export const dayOfWeeksLabel: { [key: number]: string } = {
  0: '日',
  1: '月',
  2: '火',
  3: '水',
  4: '木',
  5: '金',
  6: '土',
};

export const dayOfWeeksIndeies: { [key: string]: number } = {
  Sunday: 0,
  Monday: 1,
  Tuesday: 2,
  Wednesday: 3,
  Thursday: 4,
  Friday: 5,
  Saturday: 6,
};

export function toDayOfWeeksLabelDayOfWeek(dayOfWeek: string): string {
  const index = dayOfWeeksIndeies[dayOfWeek];
  return dayOfWeeksLabel[index];
}

export function isEqualDate(d1: IDate, d2: IDate): boolean {
  return d1.year === d2.year && d1.month === d2.month && d1.date === d2.date;
}

export function isEqualTime(t1: Time, t2: Time): boolean {
  return t1.hour === t2.hour && t1.minute === t2.minute;
}

export const isConflictDateRange = (
  dateRange: OptionalDateRange,
  otherDateRange: OptionalDateRange
): boolean => {
  const dateRangeUnix = {
    start: dateRange.start ? toDayjs(dateRange.start).unix() : 0,
    end: dateRange.end ? toDayjs(dateRange.end).unix() : Infinity,
  };
  const otherDateRangeUnix = {
    start: otherDateRange.start ? toDayjs(otherDateRange.start).unix() : 0,
    end: otherDateRange.end ? toDayjs(otherDateRange.end).unix() : Infinity,
  };

  return (
    dateRangeUnix.start <= otherDateRangeUnix.end &&
    otherDateRangeUnix.start <= dateRangeUnix.end
  );
};

/**
 * Ruleの値を正しい状態にします。
 * - timeRangesを開始時間で昇順に並び替える
 * @param rule
 */
export function normalizeRule(rule: SlotRule) {
  if (rule.type === 'regular') {
    rule.schedules.forEach((schedule) => {
      schedule.timeRanges.sort(timeRangeCompareter);
      return schedule;
    });
  }
}

const timeRangeCompareter = (a: TimeRange, b: TimeRange) => {
  const aValue = toTimeValue(a.start);
  const bValue = toTimeValue(b.start);
  if (aValue === bValue) {
    return 0;
  } else {
    return aValue < bValue ? -1 : 1;
  }
};
