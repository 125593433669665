/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';
import * as holiday_jp from '@holiday-jp/holiday_jp';
import dayjs from 'dayjs';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import useMonthlyReservations from '../../../../api/use-monthly-reservations';
import { Loading } from '../../../../components/Shared';
import { commonStyles, holiday } from '../../../../components/styles';
import {
  DateRange,
  IDate,
  toDate,
  toDayjs,
} from '../../../../core/types/reservation-types';
import { isParent } from '../../../../utils/browsers';
import { createCalendarLines } from './calendar-helper';
import { CalendarIcon } from './CalendarIcon';
import { CalendarInfoButton } from './CalendarInfoButton/CalendarInfoButton';
import TriangleLeft from './triangle-left.svg';
import TriangleRight from './triangle-right.svg';

const styles = {
  toolbar: css`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px;
    text-align: center;
    font-size: 18px;
    font-weight: 600;
  `,
  toolbarButton: css`
    border: none;
    background: none;
    margin: 0px 5px;
    cursor: pointer;
    border-radius: 50%;
    width: 24px;
    height: 24px;
    img {
      width: 12px;
      display: block;
      margin: 0 auto;
    }
    &:hover {
      background: #c5c9d1;
    }
  `,
  openCalendarButton: css`
    background: none;
    border-radius: 32px;
    border: 1px solid #172b4d;
    padding: 4px 8px;
    font-size: 14px;
    cursor: pointer;
    span {
      display: inline-block;
      vertical-align: middle;
      margin-left: 4px;
    }
  `,
  table: css`
    width: 100%;
    margin-top: 24px;
    table-layout: fixed;
    border-collapse: collapse;
    border-spacing: 0;
  `,
  dayOfWeek: css`
    font-size: 12px;
  `,
  cell: css`
    font-size: 10px;
    font-weight: bold;
    padding: 5px;
    text-align: center;
  `,
  cellCircle: css`
    border-radius: 100%;
    width: 24px;
    height: 24px;
    margin: 0 auto;
    padding-top: 2px;
    cursor: pointer;
    &:hover {
      background: #c5c9d1;
      .showRange & {
        background: #f0f0f0;
        &.today {
          & span {
            color: '#172B4D';
          }
        }
      }
    }
  `,
  reserveAvailableDate: css`
    background: #ffffff;
  `,
  currentDateCell: css`
    background: #172b4d;
    & span {
      color: #ffffff;
    }
  `,
  showRange: css`
    background: #c5c9d1;
  `,
  pastDate: css`
    cursor: auto;
    & span {
      color: #c5c9d1;
    }
    &:hover {
      & div {
        background: #e5e5e5;
      }
    }
  `,
};

type CalendarProps = {
  workspaceUid: string;
  shopUid: string;
  courseUid: string;
  dateRange: DateRange;
  primaryColor: string;
  onChangeCurrentDate: (newDate: IDate) => void;
  handlePrevMonth: (date: dayjs.Dayjs) => void;
  handleNextMonth: (date: dayjs.Dayjs) => void;
  forceOpen: boolean;
  widgetMode: boolean;
};

/**
 * カレンダー
 */
export const Calendar = ({
  workspaceUid,
  shopUid,
  courseUid,
  dateRange,
  onChangeCurrentDate,
  primaryColor,
  forceOpen,
  handlePrevMonth,
  handleNextMonth,
  widgetMode,
}: CalendarProps): JSX.Element => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  // 対象月の予約情報リスト取得API
  const {
    monthlyReservations,
    fetchMonthlyReservations,
    isLoadingMonthlyReservations,
  } = useMonthlyReservations(workspaceUid, shopUid, courseUid);

  // カレンダーで選択されている日付
  const selectedDate = useMemo(() => {
    return toDayjs(dateRange.start);
  }, [dateRange.start]);

  // 対象月文字列(YYYY-MM)
  // 選択する日付が変わっても対象月が変わらなければ
  // 月の予約情報リスト取得APIを実行しないようにするため
  const currentMonth = useMemo(() => {
    return selectedDate.format('YYYY-MM');
  }, [selectedDate]);

  // 対象月の予約情報リスト取得APIを実行
  useEffect(() => {
    void fetchMonthlyReservations(`${currentMonth}-01`);
  }, [currentMonth, fetchMonthlyReservations]);

  // カレンダー表示状態
  const [visibleCalendar, setVisibleCalendar] = useState(forceOpen);

  // カレンダー強制表示フラグが親コンポーネントから変更されたらカレンダーを表示状態にする
  useEffect(() => {
    setVisibleCalendar(forceOpen);
  }, [forceOpen]);

  const calendarContainerEl = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handler = (e: MouseEvent) => {
      if (!calendarContainerEl?.current) {
        return;
      }
      if (isParent(e.target as HTMLElement, calendarContainerEl.current)) {
        return;
      }
    };
    document.body.addEventListener('click', handler, false);
    return () => {
      document.body.removeEventListener('click', handler, false);
    };
  }, []);

  const lines = useMemo(() => {
    return createCalendarLines(dayjs(`${currentMonth}-01`));
  }, [currentMonth]);

  const isCurrentDate = (date: number | undefined): boolean => {
    if (!date) {
      return false;
    }

    return (
      dateRange.start.year === selectedDate.year() &&
      dateRange.start.month === selectedDate.month() + 1 &&
      dateRange.start.date === date
    );
  };

  const isShowRange = (date: number | undefined): boolean => {
    if (!date || widgetMode) {
      return false;
    }

    const startDate = searchParams.get('startDate');
    const startYMD = startDate != null ? new Date(startDate) : new Date();

    const rangeFirst = toDayjs({
      year: startYMD.getFullYear(),
      month: startYMD.getMonth() + 1,
      date: startYMD.getDate(),
    });

    const rangeEnd = rangeFirst.clone().add(6, 'day');

    const targetDate = toDayjs({
      year: selectedDate.year(),
      month: selectedDate.month() + 1,
      date: date,
    });

    const isAfterRangeFirst = rangeFirst <= targetDate;
    const isBeforeRangeEnd = targetDate <= rangeEnd;

    return isAfterRangeFirst && isBeforeRangeEnd;
  };

  const isPast = (date: number | undefined): boolean => {
    if (!date) {
      return false;
    }

    const today = new Date();
    const todayDate = toDayjs({
      year: today.getFullYear(),
      month: today.getMonth() + 1,
      date: today.getDate(),
    });
    const targetDate = toDayjs({
      year: selectedDate.year(),
      month: selectedDate.month() + 1,
      date: date,
    });

    return targetDate < todayDate;
  };

  const handleClickCell = useCallback(
    (date: number | undefined) => {
      if (!date) {
        return;
      }
      const newSelectedDate = selectedDate.clone().set('date', date);
      onChangeCurrentDate(toDate(newSelectedDate));
    },
    [onChangeCurrentDate, selectedDate]
  );

  const isAvailableReserve = (date: number | undefined) => {
    if (date === undefined) {
      return false;
    }

    const targetDateValues = monthlyReservations?.dates[date - 1];

    if (targetDateValues?.slots.length === 0) {
      return false;
    }

    targetDateValues?.slots.forEach((value) => {
      if (
        value.capacity.total === 0 ||
        value.capacity.total - value.reserved.total <= 0
      ) {
        return false;
      }
    });

    return true;
  };

  return (
    <div
      style={{
        position: 'relative',
        padding: '20px 20px 0px',
        maxWidth: '500px',
        margin: ' 0 auto',
        textAlign: 'center',
        zIndex: 100,
      }}
    >
      <span className="calendar-trigger" style={{ position: 'relative' }}>
        <div ref={calendarContainerEl} style={{ position: 'relative' }}>
          <div>
            {!visibleCalendar ? (
              <div>
                <div
                  css={[
                    styles.toolbar,
                    css`
                      justify-content: center;
                    `,
                  ]}
                >
                  <span>
                    {selectedDate.year()}年{selectedDate.month() + 1}月
                  </span>
                </div>
                <button
                  onClick={() => {
                    setVisibleCalendar(true);
                  }}
                  css={[
                    styles.openCalendarButton,
                    css`
                      color: ${primaryColor};
                      border-color: ${primaryColor};
                      background: transparent;
                      svg {
                        path {
                          fill: ${primaryColor};
                        }
                      }
                      :hover {
                        color: #ffffff;
                        background: ${primaryColor};
                        svg {
                          path {
                            fill: #ffffff !important;
                          }
                        }
                      }
                    `,
                  ]}
                >
                  <span>
                    <CalendarIcon />
                  </span>
                  <span>カレンダーを表示</span>
                </button>
              </div>
            ) : (
              <div css={styles.toolbar}>
                <button
                  css={styles.toolbarButton}
                  onClick={() => {
                    handlePrevMonth(selectedDate);
                  }}
                >
                  <img
                    src={TriangleLeft}
                    alt="前月"
                    css={css`
                      margin-left: -2px;
                    `}
                  />
                </button>
                <span>
                  {selectedDate.year()}年{selectedDate.month() + 1}月
                </span>
                <button
                  css={styles.toolbarButton}
                  onClick={() => {
                    handleNextMonth(selectedDate);
                  }}
                >
                  <img
                    src={TriangleRight}
                    alt="翌月"
                    css={css`
                      margin-left: 2px;
                    `}
                  />
                </button>
              </div>
            )}
            {visibleCalendar && isLoadingMonthlyReservations ? (
              <Loading primaryColor={primaryColor} />
            ) : visibleCalendar && !isLoadingMonthlyReservations ? (
              <div>
                <table css={styles.table}>
                  <thead>
                    <tr>
                      <th css={[styles.dayOfWeek, commonStyles.dayOfWeek[0]]}>
                        日
                      </th>
                      <th css={[styles.dayOfWeek, commonStyles.dayOfWeek[1]]}>
                        月
                      </th>
                      <th css={[styles.dayOfWeek, commonStyles.dayOfWeek[2]]}>
                        火
                      </th>
                      <th css={[styles.dayOfWeek, commonStyles.dayOfWeek[3]]}>
                        水
                      </th>
                      <th css={[styles.dayOfWeek, commonStyles.dayOfWeek[4]]}>
                        木
                      </th>
                      <th css={[styles.dayOfWeek, commonStyles.dayOfWeek[5]]}>
                        金
                      </th>
                      <th css={[styles.dayOfWeek, commonStyles.dayOfWeek[6]]}>
                        土
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {lines.map((line, rowIndex) => {
                      return (
                        <tr key={rowIndex}>
                          {line.map((cell, colIndex) => {
                            return (
                              <td
                                key={colIndex}
                                css={[
                                  styles.cell,
                                  isShowRange(cell?.date)
                                    ? styles.showRange
                                    : isPast(cell?.date)
                                    ? styles.pastDate
                                    : !cell?.date
                                    ? styles.pastDate
                                    : null,
                                ]}
                                className={
                                  isShowRange(cell?.date)
                                    ? `day-${colIndex} showRange`
                                    : `day-${colIndex}`
                                }
                                onClick={() => {
                                  handleClickCell(cell?.date);
                                }}
                              >
                                <div
                                  className={
                                    isCurrentDate(cell?.date) ? 'today' : ''
                                  }
                                  css={[
                                    isAvailableReserve(cell?.date)
                                      ? styles.reserveAvailableDate
                                      : null,
                                    isCurrentDate(cell?.date)
                                      ? [
                                          styles.cellCircle,
                                          styles.currentDateCell,
                                        ]
                                      : styles.cellCircle,
                                  ]}
                                >
                                  <span
                                    css={
                                      cell?.date &&
                                      holiday_jp.isHoliday(
                                        new Date(
                                          selectedDate.year(),
                                          selectedDate.month(),
                                          cell.date
                                        )
                                      )
                                        ? holiday
                                        : (commonStyles.dayOfWeek as any)[
                                            colIndex
                                          ]
                                    }
                                  >
                                    {cell?.date}
                                  </span>
                                </div>
                              </td>
                            );
                          })}
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
                <div
                  css={css`
                    display: flex;
                    justify-content: flex-end;
                  `}
                >
                  <CalendarInfoButton
                    primaryColor={primaryColor}
                    widgetMode={widgetMode}
                  />
                </div>
              </div>
            ) : null}
          </div>
        </div>
      </span>
    </div>
  );
};
