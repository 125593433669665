/** @jsxImportSource @emotion/react */

import React from 'react';
import { TextField } from '../../../../../core/types/reservation-form-types';
import { FieldErrorMessage } from '../FieldErrorMessage';
import { BaseFormFieldProps } from './BaseFormField';
import { FieldDescription } from './FieldDescription';
import { FieldLabel } from './FieldLabel';
import { buildFieldValidation } from './input-helpers';
import { styles } from './input-styles';

const buildAutoComplete = (uid: string) => {
  if (uid === 'name') {
    return 'name';
  } else if (uid === 'address') {
    return 'street-address';
  } else if (uid === 'organization') {
    return 'organization';
  } else if (uid === 'organization-title') {
    return 'organization-title';
  } else {
    return '';
  }
};

type TextFormFieldProps = BaseFormFieldProps & {
  field: TextField;
};
export function TextFormField(props: TextFormFieldProps) {
  const {
    field,
    fieldResponse,
    onChangeValue,
    validationContext,
    primaryColor,
  } = props;
  const { errors, register, trigger } = validationContext;

  const fieldValidation = buildFieldValidation(field);

  const handleChangeValue = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    onChangeValue?.(field, [newValue]);
  };

  const fieldError = errors[field.uid];

  return (
    <div>
      <div css={styles.inputLabel}>
        <div css={styles.inputTitle}>
          <FieldLabel field={field} />
        </div>
        <FieldErrorMessage field={field} errors={errors} />
      </div>
      <FieldDescription description={field.description} />
      <input
        type="text"
        name={field.uid}
        value={fieldResponse.values[0] as string}
        autoComplete={buildAutoComplete(field.uid)}
        onChange={handleChangeValue}
        ref={register(fieldValidation)}
        css={[styles.inputField, fieldError && styles.inputErrorField]}
        onBlur={async () => await trigger(field.uid)}
      />
    </div>
  );
}
