import { RegisterOptions } from 'react-hook-form';
import {
  defaultFieldCondition,
  Field,
} from '../../../../../core/types/reservation-form-types';

export const buildFieldValidation = (field: Field): RegisterOptions => {
  const requiredValue = (field.condition || defaultFieldCondition).public
    .required;
  const required = {
    value: requiredValue,
    message: '必須入力です',
  };
  return { required };
};

export const buildFieldTelValidation = (field: Field): RegisterOptions => {
  const requiredValue = (field.condition || defaultFieldCondition).public
    .required;
  const required = {
    value: requiredValue,
    message: '必須入力です',
  };
  const pattern = {
    value: /^[0-9-]+$/,
    message: '半角数値かハイフンのみで入力してください',
  };
  const validate = (input: string) => {
    // 9桁以下の電話番号は存在しない
    // https://qiita.com/tukiyo3/items/b994ffafb7f01e79fe34
    if (input.length > 0 && input.length < 10) {
      return `${field.name}が短すぎます`;
    }
    return undefined;
  };
  return { required, pattern, validate };
};

export const buildFieldEmailValidation = (field: Field): RegisterOptions => {
  const requiredValue = (field.condition || defaultFieldCondition).public
    .required;
  const required = {
    value: requiredValue,
    message: '必須入力です',
  };
  const pattern = {
    value:
      /^[a-zA-Z0-9_.%+-]+@([a-zA-Z0-9][a-zA-Z0-9-]*[a-zA-Z0-9]*\.)+[a-zA-Z]{2,}$/,
    message: 'メールアドレスの形式で入力してください',
  };
  return { required, pattern };
};
