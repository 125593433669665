import axios, { AxiosResponse } from 'axios';
import { useEffect, useState } from 'react';
import { ShopAttachedCustomDomainResponse } from '../@interfaces/custom-domain/api';
import { API_END_POINT } from './api';

export const silentAxios = axios.create();

const defaultErrorMessage = '通信時にエラーが発生しました。';
silentAxios.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    if (
      error?.response?.status &&
      error.response.status >= 400 &&
      error.response.status < 500
    ) {
      console.error(
        `${
          // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
          error.response.data.message ||
          error.response.data ||
          defaultErrorMessage
        }`
      );
    }
    return Promise.reject(error);
  }
);

export const useCustomDomainAttachedShop = (
  workspaceUid: string,
  shopUid: string
) => {
  const [isLoading, setLoading] = useState(false);
  const [response, setResponse] =
    useState<ShopAttachedCustomDomainResponse | null>(null);
  const reload = () => {
    if (!workspaceUid || !shopUid) {
      return;
    }
    setLoading(true);
    silentAxios
      .get(
        `${API_END_POINT}/public/ws/${workspaceUid}/shops/${shopUid}/custom-domain`
      )
      .then((response: AxiosResponse<ShopAttachedCustomDomainResponse>) => {
        setResponse(response.data);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  useEffect(() => {
    reload();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [workspaceUid, shopUid]);
  return {
    data: response?.customDomain || null,
    isLoading: isLoading,
  };
};
