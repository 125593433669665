/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';
import { Course } from '../../../@interfaces/course';

export const styles = {
  courseNameWrapper: css`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin: 16px auto 8px;
    margin-left: 0;
    padding: 0 16px;
  `,
  courseName: css`
    font-size: 24px;
    font-weight: 600;
    color: #172b4d;
    padding: 0 8px 0 0;
    line-height: 150%;
  `,
};

type CourseNameContentProps = {
  course: Course | undefined;
  primaryColor: string;
};

/**
 * コース名を表示
 */
export const CourseNameContent = ({
  course,
  primaryColor,
}: CourseNameContentProps): JSX.Element | null => {
  return (
    <div css={styles.courseNameWrapper}>
      <h1
        css={[
          styles.courseName,
          css`
            color: ${primaryColor};
          `,
        ]}
      >
        {course?.name || ''}
      </h1>
    </div>
  );
};
