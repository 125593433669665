/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';
import { Course } from '../../../@interfaces/course';
import { LOGO_BASE_URL } from '../../../models/theme';

type HeaderImageContentProps = {
  course: Course | undefined;
  widgetMode: boolean;
};

/**
 * ヘッダー画像を表示
 */
export const HeaderImageContent = ({
  course,
  widgetMode,
}: HeaderImageContentProps): JSX.Element | null => {
  if (!course?.imagePath || widgetMode) {
    return null;
  }
  return (
    <div
      css={css`
        width: 100%;
      `}
    >
      <img src={`${LOGO_BASE_URL}/${course.imagePath}`} alt="" width={'100%'} />
    </div>
  );
};
