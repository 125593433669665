import {
  DateRange,
  HolidayChecker,
  IDate,
  Slot,
  SlotRule,
  Time,
} from './reservation-types';

export type UID = string;
export type ResourceId = number;
export type ResourceGroupId = number;

export type IResource = {
  id: ResourceId;
  name: string;
};

export type ResouceSlotCapacity = {
  slot: Slot;
};

export type ResourceCapacity = {
  resourceId: ResourceId;
  slots: ResouceSlotCapacity[];
};

export type ResourceDateSlotCapacity = {
  date: IDate;
  resources: ResourceCapacity[];
};

export type IResourceGroup = {
  id: ResourceGroupId;
  name: string;
  resources: IResource[];
};

export type SelectedResource = {
  resourceTargetUid: UID;
  resourceId?: ResourceId;
};

export type AssignedResource = {
  resourceTargetUid: UID;
  resourceId: ResourceId;
  autoAssignment: boolean;
};

export type ResourceConstantContext = {
  groups: IResourceGroup[];
  capacities: ResourceDateSlotCapacity[];
  constants: ResourceConstant[];
  // コースの所要時間+選択されたオプションの時間
  // 設定がない場合はslotの時間が所要時間とみなす
  courseMinutesRequired?: number;
  // 画面上で選択されたリソース
  // assignmentRuleがselectの場合、
  // ユーザーが選択したリソースがここで指定される
  selectedResources: SelectedResource[];
  // 選択されたオプションの追加所要時間
  selectedOptionalFields: SelectedOptionalField[];
};

export type SelectedOptionalField = {
  fieldUid: UID;
  minuteRequired: number;
};

export type ResourceConstantType = 'serial' | 'pararel';

export const resourceConstantTypes: ResourceConstantType[] = [
  'pararel',
  'serial',
];

export type ResourceConstant = SerialResourceConstant | PararelResourceConstant;

export type BaseResourceConstant = {
  type: ResourceConstantType;
};

// ordered: 並び順通りにリソースを使用
// any: リソースの使用順を問わない
export type OrderRule = 'ordered' | 'any';

export const orderRules: OrderRule[] = ['ordered', 'any'];

export type ResourceTarget = SerialResourceTarget | PararelResourceTarget;

export type SerialResourceTarget = {
  uid: UID;
  resourceGroupId: ResourceGroupId;
  minutesRequired: number;
  assignmentRule: AssignmentRule;
  optionalFormFields?: OptionalFormField[];
};

export type OptionalFormField = {
  fieldUid: UID;
};

// 指定した複数のリソースを順番に使用可能な空きが必要
export type SerialResourceConstant = BaseResourceConstant & {
  type: 'serial';
  orderRule: OrderRule;
  targets: SerialResourceTarget[];
};

// auto-random: 自動に割り振り(ランダム)
// auto-ordered: 自動に割り振り（リソースグループ内のリソースの並び順）
// select: ユーザーが指名
export type AssignmentRule = 'autoRandom' | 'autoOrdered' | 'select';

export const assignmentRules: AssignmentRule[] = [
  'autoRandom',
  'autoOrdered',
  'select',
];

export type PararelResourceTarget = {
  uid: UID;
  resourceGroupId: ResourceGroupId;
  assignmentRule: AssignmentRule;
};

// 指定した複数のリソースが同時に使用可能な空きが必要
// (1つしか指定されない場合もある)
export type PararelResourceConstant = BaseResourceConstant & {
  type: 'pararel';
  targets: PararelResourceTarget[];
};

export type ReservedResource = {
  reservationId: number;
  resourceId: number;
  date: IDate;
  time: Time;
  minutesRequired: number;
};

export type ResourceTable = {
  resource: IResource;
  dateRange: DateRange;
  dates: ResourceDateSlotCapacity[];
};

export type ResourceSlotSetting = {
  resource: IResource;
  minutesRequired?: number;
  rules: SlotRule[];
  isHoliday: HolidayChecker;
};

export type ResourceResponse = {
  resources: ResourceTargetResponse[];
};

export type ResourceTargetResponse = {
  targetUid: UID;
  resourceId: ResourceId | undefined;
};

export const toSelectedResources = (
  resources: ResourceTargetResponse[]
): SelectedResource[] => {
  return resources.map((resource) => {
    return {
      resourceTargetUid: resource.targetUid,
      resourceId: resource.resourceId,
    };
  });
};

export const requiredConstantTargets = (constants: ResourceConstant[]) => {
  return constants.flatMap((constant) => {
    return constant.targets.filter(
      (target) => target.assignmentRule === 'select'
    );
  });
};
