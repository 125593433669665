import { SlotRule } from '../../core/types/reservation-types';
import { CourseSlotSettingWithCourse } from '../course-slot-setting';

export const PREVIEW_MESSAGE_TYPE_RESERVATION_TABLE =
  'updateReservationTablePreview';

export type ReservationTablePreviewData = {
  rules: SlotRule[];
  courseSlotSettings: CourseSlotSettingWithCourse[];
};

export const isReservationTablePreviewData = (
  data: any
): data is ReservationTablePreviewData => {
  return (
    data?.rules &&
    Array.isArray(data.rules) &&
    data?.courseSlotSettings &&
    Array.isArray(data.courseSlotSettings)
  );
};
