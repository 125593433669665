import {
  ResourceResponse,
  ResourceTargetResponse,
} from '../../../core/types/reservation-resource-types';
import useStorage from '../../../hooks/use-storage';
import {
  createDefaultResourceResponse,
  getResourceStorageKey,
} from '../../form/routes/FormPage';
import { ChangeMemberHandler } from '../components/ResourceForm';

/**
 * リソースの選択状態を扱うカスタムフック
 *
 * @param workspaceUid ワークスペースUID
 * @param courseUid コースUID
 * @returns {
 * resourceResponse: リソースの選択状態
 * setResourceResponse: リソースの選択状態を更新する関数
 * handleChangeMember: メンバーのリソースを変更する関数
 * }
 */
export const useResourceResponse = (
  workspaceUid: string,
  courseUid: string
): {
  resourceResponse: ResourceResponse;
  setResourceResponse: (resourceResponse: ResourceResponse) => void;
  handleChangeMember: ChangeMemberHandler;
} => {
  // リソースの選択状態
  const [resourceResponse, setResourceResponse] = useStorage(
    getResourceStorageKey(workspaceUid, courseUid),
    createDefaultResourceResponse(),
    'session'
  );

  const handleChangeMember: ChangeMemberHandler = (targetUid, resourceId) => {
    const exist = resourceResponse.resources.find(
      (r) => r.targetUid === targetUid
    );
    if (exist) {
      exist.resourceId = resourceId;
      setResourceResponse({ ...resourceResponse });
    } else {
      const resourceTargetResponse: ResourceTargetResponse = {
        targetUid,
        resourceId,
      };
      resourceResponse.resources.push(resourceTargetResponse);
      setResourceResponse({ ...resourceResponse });
    }
  };

  return {
    resourceResponse,
    setResourceResponse,
    handleChangeMember,
  };
};
