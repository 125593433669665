import { CourseFormSetting } from '../course-form-setting';
import { Reservation } from '../reservation';

export type ShopSms = {
  shopId: number;
  canSmsSend: boolean;
  smsShopName: string | null;
};

export const fetchTel = (
  reservation: Pick<Reservation, 'formResponse'>,
  course: Pick<CourseFormSetting, 'settingJson'>
): string | null => {
  const telUid = course.settingJson.fields.find((f) => f.type === 'tel')?.uid;
  if (!telUid) {
    return null;
  }
  const tel = reservation.formResponse.fields.find((f) => f.uid === telUid)
    ?.values[0];
  if (!tel || typeof tel !== 'string') {
    return null;
  }
  return tel.replace(/-/g, '');
};

export const isSmsSendableTel = (tel: string): boolean => {
  return tel.length === 11 && tel.startsWith('0') && !!tel.match(/[0-9]+/);
};
