import axios from 'axios';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { API_END_POINT } from '../../../api/api';
import { Loading } from '../../../components/Shared';
import { useTrackLocation } from '../../../hooks/use-track-location';
import { LineUserChannelInfo, restoreRedirectUri } from '../../../models/line';

type PageParams = {
  workspaceUid: string;
};

type Response = {
  lineUser: LineUserChannelInfo;
  redirectUrl: string;
};

export default function LineCallbackPage() {
  useTrackLocation();
  const params = new URLSearchParams(window.location.search);
  const code = params.get('code');
  const { workspaceUid } = useParams<PageParams>();

  useEffect(() => {
    if (!code) {
      return;
    }
    const redirectUri = restoreRedirectUri(
      new URLSearchParams(window.location.search)
    );

    const data = {
      code,
      callbackUrl: redirectUri,
    };
    axios
      .post<Response>(
        `${API_END_POINT}/public/ws/${workspaceUid}/line/login`,
        data
      )
      .then((res) => {
        window.location.href = res.data.redirectUrl;
      })
      .catch((e) => {
        console.error(e);
        alert('LINEログイン時にエラーが発生しました。');
      });
  }, [workspaceUid, code]);

  const error = params.get('error');
  if (error) {
    const errorDescription = params.get('error_description');
    return <>エラーが発生しました。 原因: {errorDescription}</>;
  }

  return (
    <>
      <Loading primaryColor={'#777'} />
    </>
  );
}
