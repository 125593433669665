/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';
import {
  calcTotalMinutesReqired,
  CourseSlotSetting,
} from '../../../@interfaces/course-slot-setting';
import { FormResponse } from '../../../core/types/reservation-form-types';

type MinuteRequiredInfoProps = {
  formResponse: FormResponse;
  courseSlotSetting: CourseSlotSetting | undefined;
};

/**
 * 所要時間(目安)を表示
 */
export const MinuteRequiredInfo = ({
  formResponse,
  courseSlotSetting,
}: MinuteRequiredInfoProps): JSX.Element | null => {
  if (!courseSlotSetting?.shopCourseSetting?.showsMinutesRequired) return null;
  return (
    <div
      css={css`
        display: block;
        text-align: right;
        padding: 10px 0;
        font-weight: bold;
      `}
    >
      所要時間(目安):
      {calcTotalMinutesReqired(
        formResponse,
        courseSlotSetting?.shopCourseSetting
      )}
      分
    </div>
  );
};
