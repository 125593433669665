import axios, { AxiosResponse } from 'axios';
import { useEffect, useState } from 'react';
import { ResponseBody } from '../../../frontend-api/src/handlers/public/public-courses-handler';
import { Course } from '../@interfaces/course';
import { Shop } from '../@interfaces/shop';
import { PublicShopSetting } from '../@interfaces/shop-setting';
import { Workspace } from '../@interfaces/workspace';
import { PublicWorkspaceSetting } from '../@interfaces/workspace-setting';
import { API_END_POINT } from './api';

export default function useShops(workspaceUid: string, shopUid: string) {
  const [workspace, setWorkspace] = useState<Workspace>();
  const [workspaceSetting, setWorkspaceSetting] =
    useState<PublicWorkspaceSetting>();
  const [shop, setShop] = useState<Shop>();
  const [shopSetting, setShopSetting] = useState<PublicShopSetting>();
  const [courses, setCourses] = useState<Course[]>();
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setLoading(true);
    axios
      .get(
        `${API_END_POINT}/public/ws/${workspaceUid}/shops/${shopUid}/courses`
      )
      .then((response: AxiosResponse<ResponseBody>) => {
        setWorkspace(response.data.workspace);
        setWorkspaceSetting(response.data.workspaceSetting);
        setShop(response.data.shop);
        setShopSetting(response.data.shopSetting);
        setCourses(response.data.courses);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [workspaceUid, shopUid]);
  return {
    workspace,
    workspaceSetting,
    shop,
    shopSetting,
    courses,
    isLoadingCourses: loading,
  };
}
