import { useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { ResourceGroupWithMember } from '../../../../../frontend-api/src/interfaces/resource/resource-group';
import {
  Field,
  FieldResponse,
  FieldResponseValue,
  Option,
  OptionValue,
  RadioField,
} from '../../../core/types/reservation-form-types';
import {
  requiredConstantTargets,
  ResourceConstant,
  ResourceResponse,
  ResourceTarget,
  ResourceTargetResponse,
} from '../../../core/types/reservation-resource-types';
import { RadioFormField } from '../../form/components/ReservationForm/input/RadioFormField';

export type ChangeMemberHandler = (
  targetUid: string,
  resourceId: number | undefined
) => void;

type ResourceFormProps = {
  primaryColor: string;
  constants: ResourceConstant[];
  groups: ResourceGroupWithMember[];
  resourceResponse: ResourceResponse;
  onChangeMember: ChangeMemberHandler;
};

/**
 * リソースの選択フォーム
 */
export const ResourceForm: React.VFC<ResourceFormProps> = (props) => {
  const { primaryColor, constants, groups, resourceResponse, onChangeMember } =
    props;

  const targets = useMemo(() => {
    const targets = requiredConstantTargets(constants);
    return targets.map((target) => {
      const group = groups.find((group) => group.id === target.resourceGroupId);
      const resource = resourceResponse.resources.find(
        (resource) => resource.targetUid === target.uid
      );
      return {
        target,
        group,
        resource,
      };
    });
  }, [constants, groups, resourceResponse]);

  return (
    <>
      {targets.map((target) => (
        <ResourceTargetForm
          primaryColor={primaryColor}
          target={target.target}
          group={target.group}
          resource={target.resource}
          onChangeMember={onChangeMember}
        />
      ))}
    </>
  );
};

type ResourceTargetFormProps = {
  primaryColor: string;
  target: ResourceTarget;
  group: ResourceGroupWithMember | undefined;
  resource: ResourceTargetResponse | undefined;
  onChangeMember: ChangeMemberHandler;
};

const anyoneOption: Option = {
  uid: '-1',
  text: '指定なし',
};

const ResourceTargetForm: React.VFC<ResourceTargetFormProps> = (props) => {
  const { primaryColor, target, group, resource, onChangeMember } = props;
  const validationContext = useForm();

  const field: RadioField = useMemo(() => {
    const options =
      group?.members.map((member) => {
        return {
          uid: String(member.id),
          text: member.name,
        };
      }) || [];
    return {
      uid: target.uid,
      name: group?.name || '',
      type: 'radio',
      options: [anyoneOption, ...options],
    };
  }, [target, group]);

  const fieldResponse: FieldResponse = useMemo(() => {
    const selectedOptionUid = resource?.resourceId
      ? String(resource.resourceId)
      : anyoneOption.uid;
    return {
      uid: target.uid,
      values: [{ uid: selectedOptionUid }],
    };
  }, [target, resource]);

  const onChangeValue = (field: Field, newValues: FieldResponseValue[]) => {
    const optionUid = (newValues[0] as OptionValue)?.uid;
    const resourceId =
      optionUid && optionUid !== anyoneOption.uid
        ? parseInt(optionUid)
        : undefined;
    onChangeMember(target.uid, resourceId);
  };

  return (
    <RadioFormField
      field={field}
      fieldResponse={fieldResponse}
      primaryColor={primaryColor}
      onChangeValue={onChangeValue}
      validationContext={validationContext}
    />
  );
};
