/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';
import React from 'react';
import { DeepMap, FieldError, FieldValues } from 'react-hook-form';
import { Field } from '../../../../core/types/reservation-form-types';

const styles = {
  inputError: css({
    fontSize: '12px',
    fontWeight: 600,
    color: '#F53C56',
    marginLeft: '8px',
  }),
};

type FieldErrorMessageProps = {
  field: Field;
  errors: DeepMap<FieldValues, FieldError>;
};

export function FieldErrorMessage(props: FieldErrorMessageProps) {
  const { field, errors } = props;

  const fieldError = errors[field.uid];
  if (!fieldError) {
    return null;
  }

  const message = fieldError.message;

  return <div css={styles.inputError}>{message}</div>;
}
