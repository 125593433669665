import { margeParams } from '../../../../utils/query-storage';

export const buildBackUrl = (
  workspaceUid: string,
  shopUid: string,
  courseUid: string
) => {
  const url = new URL(
    margeParams(
      `${window.location.origin}/a/${workspaceUid}/shops/${shopUid}/courses/${courseUid}`
    )
  );
  const params = new URLSearchParams(window.location.search);
  const group = params.get('group');
  const startDate = params.get('startDate');
  const endDate = params.get('endDate');
  const reservationKey = params.get('reservationKey');
  if (group) {
    url.searchParams.set('group', group);
  }
  if (startDate) {
    url.searchParams.set('startDate', startDate);
  }
  if (endDate) {
    url.searchParams.set('endDate', endDate);
  }
  if (reservationKey) {
    url.searchParams.set('reservationKey', reservationKey);
  }
  return `${url.pathname}${url.search}`;
};
