import { LOGO_BASE_URL } from '../models/theme';
import { PublicShopSetting } from '../@interfaces/shop-setting';

export const BannerImageContent = ({
  shopSetting,
}: {
  shopSetting?: PublicShopSetting;
}): JSX.Element | null => {
  if (!shopSetting?.bannerPath) {
    return null;
  }
  return (
    <div>
      {shopSetting?.bannerUrl ? (
        <a
          href={shopSetting.bannerUrl}
          target="_blank"
          rel="noreferrer noopener"
        >
          <img
            src={`${LOGO_BASE_URL}/${shopSetting.bannerPath}`}
            alt=""
            width={'100%'}
          />
        </a>
      ) : (
        <img
          src={`${LOGO_BASE_URL}/${shopSetting.bannerPath}`}
          alt=""
          width={'100%'}
        />
      )}
    </div>
  );
};
