/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';
import { Course } from '../../../@interfaces/course';
import { PublicShopSetting } from '../../../@interfaces/shop-setting';

export const styles = {
  description: css`
    font-size: 14px;
    font-weight: 300;
    color: #172b4d;
    margin: 0;
    margin-top: 16px;
    padding: 0 16px;
  `,
};

type InfoMessageProps = {
  shopSetting: PublicShopSetting | undefined;
  course: Course | undefined;
};

/**
 * 予約画面の説明文
 */
export const InfoMessage = ({
  shopSetting,
  course,
}: InfoMessageProps): JSX.Element | null => {
  if (!shopSetting) return null;
  if (shopSetting.webReservationAdvanceDays) {
    return (
      <div>
        <p css={[styles.description]}>
          {course?.dateSelectMessage ||
            'ご都合のよい時間帯をクリックし、ご予約を確定してください。'}
          <br />
          (予約は{shopSetting.webReservationAdvanceDays}日前から可能です)
        </p>
      </div>
    );
  } else {
    return (
      <p css={[styles.description]}>
        {course?.dateSelectMessage ||
          'ご都合のよい時間帯をクリックし、ご予約を確定してください。'}
      </p>
    );
  }
};
