import useStorage from '../hooks/use-storage';

export type LineUserChannelInfo = {
  userId: string;
  picture: string;
  name: string;
  channelKey: string;
};

export const SESSION_STORAGE_KEY_LINE_USER = 'lineUser';

export const useLineUser = () => {
  return useStorage<LineUserChannelInfo | undefined>(
    SESSION_STORAGE_KEY_LINE_USER,
    undefined,
    'session'
  );
};

// セッションから取得できなかった場合はパラメータから取得する
export const getLineUserFromParam = () => {
  const params = new URLSearchParams(document.location.search);
  const lineParam = params.get('_line');
  if (!lineParam) {
    return undefined;
  }
  try {
    return decodeLineUser(lineParam);
  } catch (e) {
    return undefined;
  }
};

export const getLineSessionKeyFromParam = () => {
  const params = new URLSearchParams(document.location.search);
  return params.get('_lineSessionKey') || undefined;
};

/**
 * LINEユーザーの情報を暗号化(難読化)します。
 */
export const encodeLineUser = (lineUser: LineUserChannelInfo): string => {
  return btoa(encodeURIComponent(JSON.stringify(lineUser)));
};

/**
 * LINEユーザーの情報を復号化します。
 */
export const decodeLineUser = (encoded: string): LineUserChannelInfo => {
  return JSON.parse(decodeURIComponent(atob(encoded))) as LineUserChannelInfo;
};

/**
 * LINEログイン用のリダイレクトURIを作成します。
 *
 * ログイン後にリダイレクトされる際にパラメータが付加され、
 * オリジナルのリダイレクトURIが復元できなくなるのを防ぐため、
 * 全てのパラメータを_keysにキー名を保存し、LineCallbackPageで復元します。
 *
 * 例：
 * https://res.locaop.jp/a/adlive/line?channelKey=12345&shopId=13&hoge=fuga
 * ↓
 * コールバックURI
 * https://res.locaop.jp/a/adlive/line/callback?channelKey=12345&shopId=13&hoge=fuga&_keys=channelKey%2CshopId%2Choge
 */
export const buildRedirectUri = (params: URLSearchParams): string => {
  const keys = [];
  for (const key of params.keys()) {
    keys.push(key);
  }
  const newParams = keys.map((key) => {
    const value = params.get(key) || '';
    return `${key}=${encodeURIComponent(value)}`;
  });
  newParams.push(`_keys=${encodeURIComponent(keys.join(','))}`);
  return `${window.origin}${window.location.pathname}/callback?${newParams.join(
    '&'
  )}`;
};

/**
 * LINEログイン用のリダイレクトURIを復元します。
 * パラメータ_keysに設定されたキーをもとに元のリダイレクトURIを復元します。
 * 例：
 * リダイレクトされたURL
 * https://res.locaop.jp/a/adlive/line/callback?channelKey=12345&shopId=13&hoge=fuga&_keys=channelKey%2CshopId%2Choge&state=1&moreParams=2
 * ↓
 * 復元されるコールバックURI
 * https://res.locaop.jp/a/adlive/line/callback?channelKey=12345&shopId=13&hoge=fuga&_keys=channelKey%2CshopId%2Choge
 */
export const restoreRedirectUri = (params: URLSearchParams): string => {
  const keys = (params.get('_keys') || '').split(/[,]/);
  const newParams = keys.map((key) => {
    const value = params.get(key) || '';
    return `${key}=${encodeURIComponent(value)}`;
  });
  newParams.push(`_keys=${encodeURIComponent(keys.join(','))}`);
  return `${window.origin}${window.location.pathname}?${newParams.join('&')}`;
};
