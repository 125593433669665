import axios from 'axios';

const CUSTOMER_UUID_KEY = '__customer_uuid';

export const setupLoggingRequestInterceptor = () => {
  axios.interceptors.request.use((request) => {
    request.headers = {
      ...(request.headers || {}),
      'X-Customer-UUID': getCustomerUUID(),
    };
    return request;
  });
};

export const getCustomerUUID = () => {
  let uuid = localStorage.getItem(CUSTOMER_UUID_KEY);
  if (!uuid) {
    uuid = generateUUID();
    localStorage.setItem(CUSTOMER_UUID_KEY, uuid);
  }
  return uuid;
};

export const generateUUID = () => {
  // https://github.com/GoogleChrome/chrome-platform-analytics/blob/master/src/internal/identifier.js
  // const FORMAT: string = "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx";
  const chars = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.split('');
  for (let i = 0, len = chars.length; i < len; i++) {
    switch (chars[i]) {
      case 'x':
        chars[i] = Math.floor(Math.random() * 16).toString(16);
        break;
      case 'y':
        chars[i] = (Math.floor(Math.random() * 4) + 8).toString(16);
        break;
    }
  }
  return chars.join('');
};
