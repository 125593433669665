/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';
import * as holiday_jp from '@holiday-jp/holiday_jp';
import { MouseEventHandler, useEffect, useRef, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import {
  createDate,
  DateFormat,
  DateRange,
  DateReservedSlotCapacity,
  dayOfWeeksLabel,
  IDate,
  isEqualDate,
  ReservationTable as IReservationTable,
  ReservedSlotCapacity,
  toDates,
  toDateStringByDate,
  toDateStringByDayjs,
  toDayjs,
  toTimeStringByTimeNumber,
} from '../../../core/types/reservation-types';
import Cancel from '../../../image/cancel.svg';
import DoubleCircle from '../../../image/double-circle.svg';
import Triangle from '../../../image/triangle.svg';
import { isParent } from '../../../utils/browsers';
import { getInflowSource } from '../../../utils/inflow-source';
import TriangleLeft from './calendar/triangle-left.svg';
import TriangleRight from './calendar/triangle-right.svg';
import { vacancyNum } from './ReservationTable';

const styles = {
  changeWeekButton: css`
    font-size: 16px;
    color: rgb(23, 43, 77);
    vertical-align: middle;
    cursor: pointer;
    padding: 0px 4px;
    border-bottom: 2px solid transparent;
  `,
  floatLeft: css`
    float: left;
  `,
  floatRight: css`
    float: right;
  `,
  tableLayout: css`
    padding: 20px 20px 0px;
    text-align: center;
  `,
  slotLayout: css`
    display: inline-block;
    padding: 2px;
    font-size: 14px;
    color: rgb(23, 43, 77);
    vertical-align: middle;
    cursor: pointer;
  `,
  marginRight: css`
    margin-right: 8px;
  `,
  stickyHeader: css`
    position: sticky;
    top: 0;
    z-index: 100;
  `,
  firstHeaderCell: css`
    background-color: #f7f7f7 !important;
  `,
  canNotReserve: css`
    color: #cdcdcd;
  `,
};

type Props = {
  reservationTable: IReservationTable | undefined;
  dateRange: DateRange;
  primaryColor: string;
  showsSlotCapacity: boolean;
  widgetMode: boolean;
  // プレビューの場合はtrue
  previewMode?: boolean;
  // 週の移動を行った場合に呼ばれます(プレビューモード時に使用)
  onChangeDateRange?: (dateRange: DateRange) => void;
};

/**
 * 予約テーブル（テーブル形式で表示）
 */
export default function ReservationTableVer1(props: Props) {
  const {
    dateRange,
    reservationTable,
    primaryColor,
    showsSlotCapacity,
    widgetMode,
    previewMode,
    onChangeDateRange,
  } = props;
  const location = useLocation();
  const inflowSrc = getInflowSource(location.search);
  const searchParams = new URLSearchParams(location.search);
  const reservationKey = searchParams.get('reservationKey');

  const [visibleCalendar, setVisibleCalendar] = useState(false);
  const calendarContainerEl = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handler = (e: any) => {
      if (!calendarContainerEl?.current) {
        return;
      }
      const child = e.target as HTMLElement;
      if (isParent(child, calendarContainerEl.current)) {
        return;
      }
      setVisibleCalendar(false);
    };
    document.body.addEventListener('click', handler, false);
    return () => {
      document.body.removeEventListener('click', handler, false);
    };
  }, []);

  const startDate = toDayjs(dateRange.start);
  const endDate = toDayjs(dateRange.end);

  if (!reservationTable) {
    return <div>予約情報が取得できませんでした。</div>;
  }

  const dayCount = reservationTable.dates.length;

  searchParams.set(
    'startDate',
    toDateStringByDayjs(startDate.add(-dayCount, 'day'))
  );
  searchParams.set(
    'endDate',
    toDateStringByDayjs(endDate.add(-dayCount, 'day'))
  );
  const prevUrl = `?${searchParams.toString()}`;
  const handleClickPrev: MouseEventHandler<HTMLAnchorElement> = (e) => {
    if (!previewMode) {
      return;
    }
    e.preventDefault();
    onChangeDateRange?.({
      start: createDate(
        startDate.add(-dayCount, 'day').format('YYYY-MM-DD') as DateFormat
      ),
      end: createDate(
        endDate.add(-dayCount, 'day').format('YYYY-MM-DD') as DateFormat
      ),
    });
  };

  searchParams.set(
    'startDate',
    toDateStringByDayjs(startDate.add(dayCount, 'day'))
  );
  searchParams.set(
    'endDate',
    toDateStringByDayjs(endDate.add(dayCount, 'day'))
  );
  const nextUrl = `?${searchParams.toString()}`;
  const handleClickNext: MouseEventHandler<HTMLAnchorElement> = (e) => {
    if (!previewMode) {
      return;
    }
    e.preventDefault();
    onChangeDateRange?.({
      start: createDate(
        startDate.add(dayCount, 'day').format('YYYY-MM-DD') as DateFormat
      ),
      end: createDate(
        endDate.add(dayCount, 'day').format('YYYY-MM-DD') as DateFormat
      ),
    });
  };
  const buildChangeWeekButtons = () => {
    if (widgetMode && isEqualDate(dateRange.start, dateRange.end)) {
      return null;
    }
    return (
      <div css={styles.tableLayout}>
        <Link
          to={prevUrl}
          css={[styles.changeWeekButton, styles.floatLeft]}
          onClick={handleClickPrev}
        >
          <img src={TriangleLeft} css={styles.marginRight} alt="前の週へ" />
          &nbsp;前の週へ
        </Link>
        <Link
          to={nextUrl}
          css={[styles.changeWeekButton, styles.floatRight]}
          onClick={handleClickNext}
        >
          次の週へ&nbsp;
          <img src={TriangleRight} css={styles.marginRight} alt="次の週へ" />
        </Link>
      </div>
    );
  };

  return (
    <>
      {buildChangeWeekButtons()}
      <div>
        <Table
          reservationTable={reservationTable}
          inflowSrc={inflowSrc}
          reservationKey={reservationKey}
          showsSlotCapacity={showsSlotCapacity}
          previewMode={previewMode}
        />
      </div>
    </>
  );
}

type TableProps = {
  reservationTable: IReservationTable;
  inflowSrc: string | null;
  reservationKey: string | null;
  showsSlotCapacity: boolean;
  previewMode?: boolean;
};

function Table(props: TableProps) {
  const {
    reservationTable,
    inflowSrc,
    reservationKey,
    showsSlotCapacity,
    previewMode,
  } = props;

  return (
    <table className="reservation-table">
      <Thead reservationTable={reservationTable} />
      <Tbody
        reservationTable={reservationTable}
        inflowSrc={inflowSrc}
        reservationKey={reservationKey}
        showsSlotCapacity={showsSlotCapacity}
        previewMode={previewMode}
      />
    </table>
  );
}

type TheadProps = {
  reservationTable: IReservationTable;
};

type DayCounter = {
  month: number;
  count: number;
};

/**
 * 月ごとに日付をカウントした集計結果を返します
 * @param dates
 */
function countDayByMonth(dates: IDate[]): DayCounter[] {
  const months = dates
    .map((date) => date.month)
    .reduce((prev, current) => {
      let targetGroup = prev.find((counter) => {
        return counter.month == current;
      });
      if (!targetGroup) {
        targetGroup = {
          month: current,
          count: 0,
        };
        prev.push(targetGroup);
      }
      targetGroup.count = targetGroup.count + 1;
      return prev;
    }, [] as DayCounter[]);
  return months;
}

function Thead(props: TheadProps) {
  const { reservationTable } = props;

  const dates = toDates(reservationTable.dateRange);
  const months = countDayByMonth(dates);
  const days = dates.map((date) => {
    return new Date(date.year, date.month - 1, date.date).getDay();
  });
  return (
    <thead css={styles.stickyHeader}>
      <tr>
        <th rowSpan={2} css={styles.firstHeaderCell}>
          予約
          <br />
          時間
        </th>
        {months.map((month, index) => {
          return (
            <th key={`${index}`} colSpan={month.count}>
              {month.month}月
            </th>
          );
        })}
      </tr>
      <tr className="date">
        {dates.map((date, index) => {
          return (
            <th
              key={`${index}`}
              className={
                holiday_jp.isHoliday(
                  new Date(date.year, date.month - 1, date.date)
                )
                  ? 'day-0'
                  : `day-${days[index]}`
              }
            >
              {date.date}
              <br />({dayOfWeeksLabel[days[index]]})
            </th>
          );
        })}
      </tr>
    </thead>
  );
}

type TbodyProps = {
  reservationTable: IReservationTable;
  inflowSrc: string | null;
  reservationKey: string | null;
  showsSlotCapacity: boolean;
  previewMode?: boolean;
};

function Tbody(props: TbodyProps) {
  const {
    reservationTable,
    inflowSrc,
    reservationKey,
    showsSlotCapacity,
    previewMode,
  } = props;

  const allTimes = reservationTable.dates.flatMap((date) => {
    return date.slots.map((slot) => {
      return (
        slot.slot.timeRange.start.hour * 60 + slot.slot.timeRange.start.minute
      );
    });
  });
  const times = Array.from(new Set(allTimes)).sort((a, b) => {
    return a < b ? -1 : 1;
  });

  return (
    <tbody>
      {times.map((time) => {
        return (
          <tr key={time}>
            <th>{toTimeStringByTimeNumber(time)}</th>
            {reservationTable.dates.map((date) => {
              return (
                <Td
                  key={toDateStringByDate(date.date)}
                  time={time}
                  date={date}
                  inflowSrc={inflowSrc}
                  reservationKey={reservationKey}
                  showsSlotCapacity={showsSlotCapacity}
                  previewMode={previewMode}
                />
              );
            })}
          </tr>
        );
      })}
    </tbody>
  );
}

const CalcVacancy = (props: {
  slot: ReservedSlotCapacity;
  availableReserveNum: number;
}) => {
  const { slot, availableReserveNum } = props;
  let signal;

  if (availableReserveNum >= 2) {
    signal = <img src={DoubleCircle} alt="double-circle" />;
  } else if (availableReserveNum > 0 && slot.reserved.total === 0) {
    signal = <img src={DoubleCircle} alt="double-circle" />;
  } else if (availableReserveNum === 1 && slot.reserved.total > 0) {
    signal = <img src={Triangle} alt="triangle" />;
  } else {
    signal = <img src={Cancel} alt="cancel" />;
  }
  return <div>{signal}</div>;
};

type TdProps = {
  time: number;
  date: DateReservedSlotCapacity;
  inflowSrc: string | null;
  reservationKey: string | null;
  showsSlotCapacity: boolean;
  previewMode?: boolean;
};

function Td(props: TdProps) {
  const {
    time,
    date,
    inflowSrc,
    reservationKey,
    showsSlotCapacity,
    previewMode,
  } = props;

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  if (inflowSrc) {
    searchParams.set('_src', inflowSrc);
  }
  if (reservationKey) {
    searchParams.set('reservationKey', reservationKey);
  }
  searchParams.set('date', toDateStringByDate(date.date));
  searchParams.set('time', toTimeStringByTimeNumber(time));
  const url = `${window.location.pathname}/form?${searchParams.toString()}`;
  const slot = date.slots.find((slot) => {
    const currentTime =
      slot.slot.timeRange.start.hour * 60 + slot.slot.timeRange.start.minute;
    return time == currentTime;
  });

  if (slot && slot.capacity.total > slot.reserved.total) {
    const availableReserveNum: number = vacancyNum(
      slot.capacity.total,
      slot.reserved.total,
      slot.resourceCapacity?.total
    );
    return (
      <td>
        <Link to={`${url}`} onClick={previewMode ? blockHandler : undefined}>
          <div>
            <CalcVacancy
              slot={slot}
              availableReserveNum={availableReserveNum}
            />
            {showsSlotCapacity && availableReserveNum > 0 && (
              <span css={styles.slotLayout}>残り{availableReserveNum}</span>
            )}
          </div>
        </Link>
      </td>
    );
  } else {
    return <td css={styles.canNotReserve}>×</td>;
  }
}

const blockHandler: MouseEventHandler<HTMLAnchorElement> = (e) => {
  e.preventDefault();
  alert('プレビューモードでは予約はできません。');
};
