import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import useLineLoginChannel from '../../../api/use-line-login-channel';
import { log } from '../../../api/use-logging';
import { Loading } from '../../../components/Shared';
import { useTrackLocation } from '../../../hooks/use-track-location';
import { buildRedirectUri } from '../../../models/line';
import { randomString } from '../../../utils/string';

type PageParams = {
  workspaceUid: string;
  channelKey: string;
};

export default function LineCallbackPage() {
  useTrackLocation();
  const { workspaceUid } = useParams<PageParams>();

  const { clientId } = useLineLoginChannel(workspaceUid);
  useEffect(() => {
    if (!clientId) {
      return;
    }
    const redirectUri = buildRedirectUri(
      new URLSearchParams(window.location.search)
    );
    const state = randomString(5);
    const nonce = randomString(5);
    const url = `https://access.line.me/oauth2/v2.1/authorize?response_type=code&client_id=${clientId}&redirect_uri=${encodeURIComponent(
      redirectUri
    )}&state=${state}&scope=profile%20openid&nonce=${nonce}`;
    log(`Move LINE Login URL: ${url}`)
      .then(() => {
        window.location.href = url;
      })
      .catch(() => {
        // ロギングに失敗してもLINEログインは実行
        window.location.href = url;
      });
  }, [clientId]);
  return (
    <>
      <Loading primaryColor={'#777'} />
    </>
  );
}
