/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';
import {
  defaultFieldCondition,
  Field,
} from '../../../../../core/types/reservation-form-types';

export const styles = {
  required: css({
    color: '#F53C56',
  }),
};

type FieldLabelProps = {
  field: Field;
};

export const FieldLabel = ({ field }: FieldLabelProps) => {
  const required = (field.condition || defaultFieldCondition).public.required;
  return (
    <div>
      {field.name}
      {required ? <span css={styles.required}>*</span> : null}
    </div>
  );
};
