import { useEffect } from 'react';
import { PublicShopSetting } from '../@interfaces/shop-setting';
import { PublicWorkspaceSetting } from '../@interfaces/workspace-setting';
import { insertUserScripts } from '../utils/browsers';

let initializedWorkspaceUserScript = false;
let initializedShopUserScript = false;

export default function useUserScript(
  workspaceSetting: PublicWorkspaceSetting | undefined,
  shopSetting: PublicShopSetting | undefined
) {
  useEffect(() => {
    // GA等のスクリプトの読み込み
    if (!workspaceSetting || initializedWorkspaceUserScript) {
      return;
    }
    if (workspaceSetting.userScriptHead) {
      insertUserScripts(workspaceSetting.userScriptHead, 'head_last');
    }
    if (workspaceSetting.userScriptBody) {
      insertUserScripts(workspaceSetting.userScriptBody, 'body_first');
    }
    initializedWorkspaceUserScript = true;
  }, [workspaceSetting]);

  useEffect(() => {
    // GA等のスクリプトの読み込み
    if (!shopSetting || initializedShopUserScript) {
      return;
    }
    if (shopSetting.userScriptHead) {
      insertUserScripts(shopSetting.userScriptHead, 'head_last');
    }
    if (shopSetting.userScriptBody) {
      insertUserScripts(shopSetting.userScriptBody, 'body_first');
    }
    initializedShopUserScript = true;
  }, [shopSetting]);
}
