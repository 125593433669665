import {
  BaseSlotSetting,
  Capacity,
  DateRange,
  DateSlotCapacity,
  HolidayChecker,
  HolidayRule,
  IDate,
  includesDate,
  isEqualDate,
  isEqualTime,
  matchSchedule,
  RegularRule,
  ReservationTable,
  ReservedReservation,
  ReservedSlotCapacity,
  Slot,
  SlotCapacity,
  SlotSetting,
  TimeRange,
  TimeRangeCapacity,
  toDates,
  toTimeByTimeValue,
  toTimeValue,
} from '../types/reservation-types';

export class ReservationTableService {
  buildReservationTable(
    setting: SlotSetting,
    dateRange: DateRange,
    reservations: ReservedReservation[]
  ): ReservationTable {
    const dates = this.buildSlotCapacities(setting, dateRange).map(
      (dateSlotCapacity) => {
        const date = dateSlotCapacity.date;
        const slots: ReservedSlotCapacity[] = dateSlotCapacity.slots.map(
          (slot: SlotCapacity) => {
            const targets = reservations.filter((r) => {
              if (r.minutesRequired) {
                const offsetTime = toTimeValue(slot.slot.timeRange.start);
                const startTime = toTimeValue(r.time);
                const endTime = startTime + r.minutesRequired;
                return (
                  isEqualDate(r.date, date) &&
                  offsetTime >= startTime &&
                  offsetTime < endTime
                );
              } else {
                return (
                  isEqualDate(r.date, date) &&
                  isEqualTime(r.time, slot.slot.timeRange.start)
                );
              }
            });
            const total = targets.reduce((prev, total) => {
              return prev + total.total;
            }, 0);
            const courseTotal = targets
              .filter((t) => t.courseId === setting.courseId)
              .reduce((prev, total) => {
                return prev + total.total;
              }, 0);
            const canReserve = total < slot.capacity.total;
            return {
              ...slot,
              reserved: {
                total,
                courseTotal,
                canReserve,
              },
            } as ReservedSlotCapacity;
          }
        );
        // 所要時間によって予約可能かどうかを追加判定
        if (setting.minutesRequired) {
          for (const slot of slots) {
            // トータルで空きがなければ追加判定不要
            if (slot.reserved.canReserve == false) {
              continue;
            }
            // 必要な所要時間分予約可能な枠が空いているかどうか？
            const needTimeRange: TimeRange = {
              start: slot.slot.timeRange.start,
              end: toTimeByTimeValue(
                toTimeValue(slot.slot.timeRange.start) + setting.minutesRequired
              ),
            };
            const availableSlotsRanges = slots
              .filter((s) => s.reserved.canReserve)
              .map((s) => s.slot.timeRange);
            slot.reserved.canReserve = canReserveInSlotRanges(
              availableSlotsRanges,
              needTimeRange
            );
          }
        }
        // NOTE: YOYAKU-636 の対応により「所要時間考慮の枠としては予約可能だがその時間開始の予約は不可」に対応するための処理
        const startAllowedSlots = slots.map((s) => {
          if (s.capacity.startNotAllowed === true) {
            s.reserved.canReserve = false;
          }
          return s;
        });
        return {
          date,
          slots: startAllowedSlots,
        };
      }
    );
    return {
      dateRange,
      dates,
    };
  }

  buildSlotCapacities(
    setting: SlotSetting,
    dateRange: DateRange
  ): DateSlotCapacity[] {
    return toDates(dateRange).map((date) => {
      const slots = getSlotCapacity(setting, date);
      return {
        date,
        slots,
      };
    });
  }
}

export function getSlotCapacity(
  setting: BaseSlotSetting,
  date: IDate
): SlotCapacity[] {
  let slots;
  for (const rule of setting.rules) {
    if (includesDate(rule.dateRange, date)) {
      let results;
      if (rule.type == 'regular') {
        const { unit } = rule;
        results = getRegularSlotCapacity(unit, rule, date, setting.isHoliday);
      } else if (rule.type == 'holiday') {
        results = getHolidaySlotCapacity(rule, date);
      }
      // あとのルールの結果で上書き
      if (results) {
        slots = results;
      }
    }
  }
  return slots || [];
}

function getRegularSlotCapacity(
  unit: number,
  rule: RegularRule,
  date: IDate,
  isHoliday: HolidayChecker
): SlotCapacity[] | undefined {
  const { schedules } = rule;
  for (const schedule of [...schedules].reverse()) {
    if (!matchSchedule(schedule.condition, date, isHoliday)) {
      continue;
    }
    const { timeRanges, capacity } = schedule;
    const slots = getSlotsByTimeRanges(timeRanges, unit, capacity);
    return slots;
  }
  return [];
}

function getHolidaySlotCapacity(
  rule: HolidayRule,
  date: IDate
): SlotCapacity[] | undefined {
  const { dateRange } = rule;
  return includesDate(dateRange, date) ? [] : undefined;
}

export function getSlotsByTimeRanges(
  timeRanges: TimeRangeCapacity[],
  unit: number,
  capacity: Capacity | undefined
): SlotCapacity[] {
  return timeRanges.flatMap((timeRange) =>
    getSlotsByTimeRange(timeRange, unit, capacity)
  );
}

export function getSlotsByTimeRange(
  timeRange: TimeRangeCapacity,
  unit: number,
  capacity: Capacity | undefined
): SlotCapacity[] {
  const { start, end } = timeRange;
  const startTimeValue = toTimeValue(start);
  const endTimeValue = toTimeValue(end);
  const slots = [];
  for (let current = startTimeValue; current < endTimeValue; current += unit) {
    const slot: SlotCapacity = {
      slot: {
        timeRange: {
          start: toTimeByTimeValue(current),
          end: toTimeByTimeValue(Math.min(current + unit, endTimeValue)),
        },
      },
      capacity: timeRange.capacity || capacity || { total: 0 },
    };
    slots.push(slot);
  }
  return slots;
}

export const canReserveInSlotRanges = (
  timeRanges: TimeRange[],
  targetRange: TimeRange
): boolean => {
  let currentTime = toTimeValue(targetRange.start);
  const endTime = toTimeValue(targetRange.end);
  while (currentTime < endTime) {
    const range = timeRanges.find(
      (tr) =>
        toTimeValue(tr.start) <= currentTime &&
        toTimeValue(tr.end) > currentTime
    );
    if (!range) {
      return false;
    }
    currentTime = toTimeValue(range.end);
  }
  return true;
};
