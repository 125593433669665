/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';
import React from 'react';
import { getLineSessionKeyFromParam } from '../../../../models/line';

export const LineLoginAutoFillNotice = (): JSX.Element | null => {
  const lineSessionKey = getLineSessionKeyFromParam();
  if (!lineSessionKey) {
    return null;
  }
  return (
    <div
      className="form-message"
      css={css`
        margin-top: 16px;
      `}
    >
      ※入力された情報は次回以降の予約時に自動的に入力されます。
    </div>
  );
};
