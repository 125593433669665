/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';
import dayjs from 'dayjs';
import {
  MouseEventHandler,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Link, useLocation } from 'react-router-dom';
import { commonStyles } from '../../../components/styles';
import {
  createDate,
  DateFormat,
  DateRange,
  DateReservedSlotCapacity,
  isEqualDate,
  ReservationTable as IReservationTable,
  ReservedSlotCapacity,
  toDateStringByDayjs,
  toDayjs,
  toTimeValue,
} from '../../../core/types/reservation-types';
import Cancel from '../../../image/cancel.svg';
import DoubleCircle from '../../../image/double-circle.svg';
import Triangle from '../../../image/triangle.svg';
import { toDoubleDigits } from '../../../utils/browsers';
import TriangleLeft from './calendar/triangle-left.svg';
import TriangleRight from './calendar/triangle-right.svg';

type Props = {
  reservationTable: IReservationTable | undefined;
  dateRange: DateRange;
  primaryColor: string;
  courseMinutesRequired: number | undefined;
  isNoData: boolean;
  showsSlotCapacity: boolean;
  widgetMode: boolean;
  hidesNoSlotDate: boolean;
  // プレビューの場合はtrue
  previewMode?: boolean;
  // 週の移動を行った場合に呼ばれます(プレビューモード時に使用)
  onChangeDateRange?: (dateRange: DateRange) => void;
};

const styles = {
  commonGap: css({
    padding: '0 24px',
  }),
  reserveDate: css({
    padding: '8px 16px 16px',
    fontSize: '16px',
    fontWeight: 'bold',
    color: '#172B4D',
    borderBottom: '2px solid #C5C9D1',
  }),
  closingDay: css({
    background: '#FFFFFF',
  }),
  todayBg: css({
    background: '#C5C9D1',
  }),
  date: css({
    position: 'sticky',
    top: 0,
    background: 'rgb(242, 242, 242)',
    padding: '8px 0',
    '.closingDay &': {
      opacity: '0.5',
      background: '#FFFFFF',
    },
    '.todayBg &': {
      background: '#C5C9D1',
    },
  }),
  todayLabel: css({
    fontSize: '10px',
    fontWeight: 'bold',
    padding: '4px 8px',
    borderRadius: '32px',
    background: '#172B4D',
    color: '#FFFFFF',
    verticalAlign: 'middle',
    marginclosingDayLeft: '8px',
  }),
  reserves: css({
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    flexWrap: 'wrap',
  }),
  calendarButton: css({
    border: 'none',
    background: 'none',
    fontSize: '14px',
    fontWeight: 'bold',
  }),
  reserveInfo: css({
    background: '#FFFFFF',
    borderRadius: '10px',
    padding: '8px',
    width: 'calc((100% - 24px) / 3)',
    maxWidth: '120px',
    textAlign: 'center',
    margin: '8px 4px',
    border: '2px solid #172B4D',
    cursor: 'pointer',
    '&:hover': {
      background: '#172B4D',
      color: '#FFFFFF',
      img: {
        filter:
          'invert(88%) sepia(61%) saturate(0%) hue-rotate(229deg) brightness(107%) contrast(101%)',
      },
    },
  }),
  fullReserve: css({
    background: 'transparent',
    borderColor: '#8c8c8c',
    color: '#8c8c8c',
    cursor: 'auto',
    img: {
      filter:
        'invert(50%) sepia(0%) saturate(11%) hue-rotate(143deg) brightness(101%) contrast(93%)',
    },
    '&:hover': {
      background: 'transparent',
      color: '#8c8c8c',
      img: {
        filter:
          'invert(50%) sepia(0%) saturate(11%) hue-rotate(143deg) brightness(101%) contrast(93%)',
      },
    },
    '.todayBg &': {
      borderColor: '#ffffff',
      color: '#ffffff',
      '&:hover': {
        color: '#ffffff',
      },
      img: {
        filter: 'none',
      },
    },
  }),
  changeWeekButtonContainer: css({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '0 16px',
  }),
  changeWeekButton: css({
    fontSize: '16px',
    color: '#172B4D',
    verticalAlign: 'middle',
    cursor: 'pointer',
    padding: '0 4px',
    borderBottom: '2px solid transparent',
    'img, span': {
      verticalAlign: 'middle',
    },
    '&:hover': {
      borderBottom: '2px solid #172B4D',
    },
    '@media (max-width:320px)': {
      fontSize: '12px',
    },
  }),
  description: css({
    fontSize: '14px',
    fontWeight: 300,
    color: '#172B4D',
    margin: 0,
    marginTop: '16px',
    padding: '0 16px',
  }),
};

/**
 * 予約テーブル（一覧形式）
 */
export default function ReservationTable(props: Props) {
  const {
    dateRange,
    reservationTable,
    primaryColor,
    courseMinutesRequired,
    isNoData,
    showsSlotCapacity,
    widgetMode,
    hidesNoSlotDate,
    previewMode,
    onChangeDateRange,
  } = props;
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  const startDate = toDayjs(
    reservationTable?.dateRange.start || dateRange.start
  );
  const endDate = toDayjs(reservationTable?.dateRange.end || dateRange.end);

  const dayCount = reservationTable?.dates.length || 0;

  searchParams.set(
    'startDate',
    toDateStringByDayjs(startDate.add(-dayCount, 'day'))
  );
  searchParams.set(
    'endDate',
    toDateStringByDayjs(endDate.add(-dayCount, 'day'))
  );

  const prevUrl = `?${searchParams.toString()}`;
  const handleClickPrev: MouseEventHandler<HTMLAnchorElement> = (e) => {
    if (!previewMode) {
      return;
    }
    e.preventDefault();
    onChangeDateRange?.({
      start: createDate(
        startDate.add(-dayCount, 'day').format('YYYY-MM-DD') as DateFormat
      ),
      end: createDate(
        endDate.add(-dayCount, 'day').format('YYYY-MM-DD') as DateFormat
      ),
    });
  };

  searchParams.set(
    'startDate',
    toDateStringByDayjs(startDate.add(dayCount, 'day'))
  );
  searchParams.set(
    'endDate',
    toDateStringByDayjs(endDate.add(dayCount, 'day'))
  );

  const handleClickNext: MouseEventHandler<HTMLAnchorElement> = (e) => {
    if (!previewMode) {
      return;
    }
    e.preventDefault();
    onChangeDateRange?.({
      start: createDate(
        startDate.add(dayCount, 'day').format('YYYY-MM-DD') as DateFormat
      ),
      end: createDate(
        endDate.add(dayCount, 'day').format('YYYY-MM-DD') as DateFormat
      ),
    });
  };

  const nextUrl = `?${searchParams.toString()}`;

  const buildChangeWeekButtons = () => {
    if (widgetMode && isEqualDate(dateRange.start, dateRange.end)) {
      return null;
    }
    return (
      <div>
        <div css={styles.changeWeekButtonContainer}>
          <Link
            to={prevUrl}
            css={styles.changeWeekButton}
            onClick={handleClickPrev}
          >
            <img
              src={TriangleLeft}
              style={{ marginRight: '8px' }}
              alt="前の週へ"
            />
            <span>前の週へ</span>
          </Link>
          <div
            style={{
              textAlign: 'center',
              fontWeight: 'bold',
              fontSize: '14px',
            }}
          >
            {`${startDate.format('M月D日')}〜${endDate.format('M月D日')}`}
          </div>
          <Link
            to={nextUrl}
            css={styles.changeWeekButton}
            onClick={handleClickNext}
          >
            <span>次の週へ</span>
            <img
              src={TriangleRight}
              style={{ marginLeft: '8px' }}
              alt="次の週へ"
            />
          </Link>
        </div>
      </div>
    );
  };

  return (
    <div>
      {isNoData ? (
        <div style={{ marginTop: '32px', padding: '0 20px' }}>
          予約情報が取得できませんでした。
        </div>
      ) : (
        <div style={{ marginTop: '20px' }}>
          <div style={{ marginBottom: '8px' }}>{buildChangeWeekButtons()}</div>
          {reservationTable?.dates.map((reserve, index) => {
            if (hidesNoSlotDate && reserve.slots.length === 0) {
              return null;
            }
            return (
              <div key={index}>
                <ReserveDate
                  reserve={reserve}
                  index={index}
                  courseMinutesRequired={courseMinutesRequired}
                  showsSlotCapacity={showsSlotCapacity}
                  previewMode={previewMode}
                />
              </div>
            );
          })}
          {reservationTable && (
            <div style={{ marginTop: '8px' }}>{buildChangeWeekButtons()}</div>
          )}
        </div>
      )}
    </div>
  );
}

/**
 * 予約可能数を計算する
 * @param capacityNum 予約枠の予約可能数上限
 * @param reservedNum 予約済みの予約数
 * @param resourceCapacityTotal この予約枠に紐づくリソースで計算した予約可能数上限 undefinedの場合はリソースの残数は考慮しない
 * @returns 予約可能数
 */
export const vacancyNum = (
  capacityNum: number,
  reservedNum: number,
  resourceCapacityTotal: number | undefined
) => {
  // リソースの残数が設定されている場合はそれを優先して表示する
  if (resourceCapacityTotal !== undefined) {
    return Math.min(resourceCapacityTotal, capacityNum - reservedNum);
  }
  // そうでない場合は、コースの残数を表示する
  return capacityNum - reservedNum;
};

const ReserveDate = (props: {
  reserve: DateReservedSlotCapacity;
  index: number;
  courseMinutesRequired: number | undefined;
  showsSlotCapacity: boolean;
  previewMode?: boolean;
}): JSX.Element => {
  const {
    reserve,
    index,
    courseMinutesRequired,
    showsSlotCapacity,
    previewMode,
  } = props;
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const reservationsElem = useRef<HTMLDivElement>(null);
  const isFirst = index === 0;
  const isInIframe = window.parent !== window;
  const [isShow, setIsShow] = useState<boolean>(isFirst || isInIframe);

  const toggleVisibility = useCallback(() => {
    if (isShow) {
      return;
    }
    const windowScroll = window.pageYOffset;
    const windowHeight = window.innerHeight;
    const pos = reservationsElem.current?.getBoundingClientRect().top || 0;
    const offset = 100;

    if (windowScroll > pos - windowHeight + offset) {
      setIsShow(true);
      window.removeEventListener('scroll', toggleVisibility);
    }
  }, [isShow]);

  useEffect(() => {
    if (isShow) {
      return;
    }
    toggleVisibility();
    window.addEventListener('scroll', toggleVisibility);
    return () => {
      window.removeEventListener('scroll', toggleVisibility);
    };
  }, [index, toggleVisibility, isShow]);

  dayjs.locale('ja');

  const targetDate = dayjs()
    .year(reserve.date.year)
    .month(reserve.date.month - 1)
    .date(reserve.date.date);

  const isToday = dayjs().format('YYYYMMDD') === targetDate.format('YYYYMMDD');
  const dayOfWeek: number = targetDate.day();

  const DateInfo = (): JSX.Element => {
    return (
      <div>
        {targetDate.format('YYYY/MM/DD (ddd)')}
        {isToday && <span css={styles.todayLabel}>本日</span>}
      </div>
    );
  };

  const SlotInfo = (props: {
    slot: ReservedSlotCapacity;
    isNumeric: boolean;
  }): JSX.Element => {
    const { slot, isNumeric } = props;
    const reservationKey = searchParams.get('reservationKey');

    if (reservationKey) {
      searchParams.set('reservationKey', reservationKey);
    }
    searchParams.set('date', targetDate.format('YYYY-MM-DD'));
    searchParams.set(
      'time',
      `${slot.slot.timeRange.start.hour}:${slot.slot.timeRange.start.minute}`
    );
    const url = `${window.location.pathname}/form?${searchParams.toString()}`;

    const availableReserveNum: number = vacancyNum(
      slot.capacity.total,
      slot.reserved.total,
      slot.resourceCapacity?.total
    );

    const toForm = () => {
      if (0 < availableReserveNum) {
        window.location.href = url;
      }
    };

    return (
      <div onClick={previewMode ? blockHandler : toForm}>
        <div>
          {toDoubleDigits(slot.slot.timeRange.start.hour)}:
          {toDoubleDigits(slot.slot.timeRange.start.minute)}
        </div>
        <div>
          {isNumeric && availableReserveNum > 0 ? (
            <span style={{ fontWeight: 'normal', fontSize: '14px' }}>
              残り{availableReserveNum}
            </span>
          ) : (
            <CalcVacancy
              slot={slot}
              availableReserveNum={availableReserveNum}
            />
          )}
        </div>
      </div>
    );
  };

  const CalcVacancy = (props: {
    slot: ReservedSlotCapacity;
    availableReserveNum: number;
  }) => {
    const { slot, availableReserveNum } = props;
    let signal;

    if (availableReserveNum >= 2) {
      signal = <img src={DoubleCircle} alt="double-circle" />;
    } else if (availableReserveNum > 0 && slot.reserved.total === 0) {
      signal = <img src={DoubleCircle} alt="double-circle" />;
    } else if (availableReserveNum === 1 && slot.reserved.total > 0) {
      signal = <img src={Triangle} alt="triangle" />;
    } else {
      signal = <img src={Cancel} alt="cancel" />;
    }
    return <div>{signal}</div>;
  };

  const dayOfWeekCss = () => {
    switch (dayOfWeek) {
      case 0:
        return commonStyles.dayOfWeek[0];
      case 1:
        return commonStyles.dayOfWeek[1];
      case 2:
        return commonStyles.dayOfWeek[2];
      case 3:
        return commonStyles.dayOfWeek[3];
      case 4:
        return commonStyles.dayOfWeek[4];
      case 5:
        return commonStyles.dayOfWeek[5];
      case 6:
        return commonStyles.dayOfWeek[6];
      default:
        return commonStyles.dayOfWeek[1];
    }
  };

  // 最終時間から必要なコースの所要時間が確保できない枠は表示しない(YOYAKU-515)
  const removeUnavailableLastSlots = (
    slots: ReservedSlotCapacity[],
    minutesRequired: number | undefined
  ): ReservedSlotCapacity[] => {
    if (slots.length === 0 || !minutesRequired) {
      return slots;
    }
    const limitTime =
      toTimeValue(slots[slots.length - 1].slot.timeRange.end) - minutesRequired;
    return slots.filter(
      (slot) => toTimeValue(slot.slot.timeRange.start) <= limitTime
    );
  };

  const availableLastSlots = useMemo(() => {
    return removeUnavailableLastSlots(reserve.slots, courseMinutesRequired);
  }, [reserve.slots, courseMinutesRequired]);

  return (
    <div
      ref={reservationsElem}
      css={[
        styles.reserveDate,
        isToday
          ? styles.todayBg
          : reserve.slots.length === 0
          ? styles.closingDay
          : null,
      ]}
      className={
        isToday
          ? 'todayBg'
          : reserve.slots.length === 0
          ? 'closingDay'
          : undefined
      }
    >
      <div css={[styles.date, dayOfWeekCss()]}>
        <DateInfo />
      </div>
      {isShow && (
        <div css={styles.reserves}>
          {availableLastSlots.map((slot, index) => {
            return (
              <div
                key={index}
                css={[
                  styles.reserveInfo,
                  vacancyNum(
                    slot.capacity.total,
                    slot.reserved.total,
                    slot.resourceCapacity?.total
                  ) <= 0
                    ? styles.fullReserve
                    : null,
                ]}
              >
                <SlotInfo slot={slot} isNumeric={!!showsSlotCapacity} />
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

const blockHandler: MouseEventHandler<HTMLElement> = (e) => {
  e.preventDefault();
  alert('プレビューモードでは予約はできません。');
};
