import dayjs from 'dayjs';
import { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
  createDateRange,
  DateFormat,
  DateRange,
  IDate,
  isEqualDate,
  toDate,
  toDateStringByDate,
  toDayjs,
} from '../../../core/types/reservation-types';

/**
 * 日付範囲とその操作を扱うカスタムフック
 *
 * @param startDate クエリパラメータを元に算出した開始日文字列
 * @param endDate クエリパラメータを元に算出した終了日文字列
 * @returns {
 *  dateRange: 日付範囲
 *  handleChangeCurrentDate: 日付範囲の開始日を変更する関数
 *  handlePrevMonth: 前月ボタンを押したときの処理
 *  handleNextMonth: 次月ボタンを押したときの処理
 * }
 */
export const useDateRange = (
  startDate: DateFormat,
  endDate: DateFormat
): {
  dateRange: DateRange;
  handleChangeCurrentDate: (newCurrentDate: IDate) => void;
  handlePrevMonth: (date: dayjs.Dayjs) => void;
  handleNextMonth: (date: dayjs.Dayjs) => void;
} => {
  const history = useHistory();
  const [dateRange, setDateRange] = useState<DateRange>(
    createDateRange(startDate, endDate)
  );

  // 日付範囲のリクエストパラメータが変わった場合予約情報をリロードする処理
  // 日付範囲の変更はURLの変更でReact Routerを通してこのコンポーネントに通知される
  useEffect(() => {
    setDateRange(createDateRange(startDate, endDate));
  }, [startDate, endDate]);

  const handleChangeCurrentDate = useCallback(
    (newCurrentDate: IDate) => {
      const newEndDate = (() => {
        if (isEqualDate(dateRange.start, dateRange.end)) {
          return newCurrentDate;
        } else {
          const diffDate = toDayjs(dateRange.end).diff(
            toDayjs(dateRange.start),
            'day'
          );
          return toDate(toDayjs(newCurrentDate).add(diffDate, 'day'));
        }
      })();
      const newDateRange: DateRange = {
        start: newCurrentDate,
        end: newEndDate,
      };
      const searchParams = new URL(window.location.href).searchParams;
      searchParams.set('startDate', toDateStringByDate(newDateRange.start));
      searchParams.set('endDate', toDateStringByDate(newDateRange.end));
      history.push(`${document.location.pathname}?${searchParams.toString()}`);
    },
    [dateRange.end, dateRange.start, history]
  );

  const handlePrevMonth = (date: dayjs.Dayjs) => {
    const newSelectedDate = date.clone().add(-1, 'month').startOf('month');
    const newCurrentDate: IDate = {
      year: newSelectedDate.year(),
      month: newSelectedDate.month() + 1,
      date: newSelectedDate.date(),
    };
    handleChangeCurrentDate(newCurrentDate);
  };

  const handleNextMonth = (date: dayjs.Dayjs) => {
    const newSelectedDate = date.clone().add(1, 'month').startOf('month');
    const newCurrentDate: IDate = {
      year: newSelectedDate.year(),
      month: newSelectedDate.month() + 1,
      date: newSelectedDate.date(),
    };

    handleChangeCurrentDate(newCurrentDate);
  };

  return {
    dateRange,
    handleChangeCurrentDate,
    handlePrevMonth,
    handleNextMonth,
  };
};
