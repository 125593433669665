/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import ReactLoading from 'react-loading';
import { useLocation } from 'react-router-dom';
import { Course } from '../../../@interfaces/course';
import { Shop } from '../../../@interfaces/shop';
import { PublicShopSetting } from '../../../@interfaces/shop-setting';
import { Workspace } from '../../../@interfaces/workspace';
import { cancelReservation } from '../../../api/use-reservation';
import { styles as commonStyles } from '../../../components/styles';
import { FormSetting } from '../../../core/types/reservation-form-types';
import { DateTime } from '../../../core/types/reservation-types';
import { lineBreakToParagraphAndHtml } from '../../../utils/browsers';
import { getInflowSource } from '../../../utils/inflow-source';

const styles = {
  inputLabel: css({
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    paddingBottom: '8px',
  }),
  inputTitle: css({
    paddingLeft: '16px',
    fontSize: '14px',
    fontWeight: 'bold',
    marginTop: '16px',
  }),
  required: css({
    color: '#F53C56',
  }),
  inputField: css({
    width: '100%',
    fontSize: '16px',
    border: '1px solid #C5C9D1',
    borderRadius: '10px',
    padding: '12px',
  }),
  inputErrorField: css({
    border: '1px solid #F53C56 !important',
  }),
  reserveCancelButton: css({
    display: 'flex',
    justifyContent: 'center',
    color: '#fff',
    width: '70%',
    maxWidth: '350px',
    margin: '0 auto',
    borderRadius: '56px',
    border: 'none',
    padding: '16px 0',
    fontSize: '18px',
    backgroundColor: '#FFFFFF',
    fontWeight: 'bold',
    cursor: 'pointer',
    '&:disabled': {
      opacity: '0.6',
    },
    '@media (max-width:320px)': {
      fontSize: '14px',
    },
  }),
};

type Props = {
  workspace: Workspace | undefined;
  shop: Shop | undefined;
  shopSetting: PublicShopSetting | undefined;
  course: Course | undefined;
  dateTime: DateTime;
  formSetting: FormSetting | undefined;
  formHeader: string | undefined;
  formFooter: string | undefined;
  primaryColor: string;
  reservationKey: string | null;
};

export default function CancelReservationForm(props: Props) {
  const {
    workspace,
    shop,
    shopSetting,
    course,
    dateTime,
    formSetting,
    formHeader,
    formFooter,
    primaryColor,
    reservationKey,
  } = props;

  const location = useLocation();
  const inflowSrc = getInflowSource(location.search);
  const [submitting, setSubmitting] = useState(false);
  const [canceled, setCanceled] = useState(false);
  const [reason, setReason] = useState('');

  if (!workspace) {
    return <div>ワークスペース情報が取得できませんでした。</div>;
  }
  if (!shop) {
    return <div>店舗情報が取得できませんでした。</div>;
  }
  if (!course) {
    return <div>コース情報が取得できませんでした。</div>;
  }
  if (!formSetting) {
    return <div>予約フォーム情報が取得できませんでした。</div>;
  }

  const handleSubmitFormCancelReservation = (e: React.FormEvent) => {
    if (submitting) {
      return;
    }
    setSubmitting(true);
    cancelReservation(
      workspace.uid,
      shop.uid,
      course.uid,
      reservationKey,
      reason
    )
      .then(() => {
        setCanceled(true);
      })
      .catch((e) => {
        window.alert('予約のキャンセルに失敗しました。');
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  const buildContent = () => {
    if (canceled) {
      return (
        <div>
          <div className="form-message">
            上記の予約のキャンセルを受け付けました。
          </div>
          <div className="form-message">この画面を閉じて終了してください。</div>
        </div>
      );
    } else {
      return (
        <div>
          <InputForm
            shopSetting={shopSetting}
            formSetting={formSetting}
            formFooter={formFooter}
            onSubmitFormCancelReservation={handleSubmitFormCancelReservation}
            submitting={submitting}
            primaryColor={primaryColor}
            reservationKey={reservationKey}
            reason={reason}
            onChangeReason={setReason}
          />
        </div>
      );
    }
  };

  return (
    <div>
      {formHeader ? (
        <div className="form-message">
          {lineBreakToParagraphAndHtml(formHeader)}
        </div>
      ) : null}
      {buildContent()}
    </div>
  );
}

type FormProps = {
  shopSetting: PublicShopSetting | undefined;
  formSetting: FormSetting;
  formFooter: string | undefined;
  onSubmitFormCancelReservation: (e: React.FormEvent) => void;
  submitting: boolean;
  primaryColor: string;
  reservationKey: string | null;
  reason: string;
  onChangeReason: (reason: string) => void;
};

function InputForm(props: FormProps) {
  const {
    shopSetting,
    formSetting,
    formFooter,
    onSubmitFormCancelReservation,
    submitting,
    primaryColor,
    reservationKey,
    reason,
    onChangeReason,
  } = props;

  const { handleSubmit, formState, register } = useForm();

  const disabledSubmit =
    submitting || (reason.trim() === '' && !shopSetting?.hideCancelReason);

  const handleChangeReason = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChangeReason(e.target.value);
  };

  const buildLoading = () => {
    return (
      <div>
        {submitting ? (
          <ReactLoading
            className="loading"
            type="spokes"
            color={'#fff'}
            height={'20px'}
            width={'20px'}
          />
        ) : null}
      </div>
    );
  };

  const buildCancelReservationForm = () => {
    return (
      <div>
        <form onSubmit={handleSubmit(onSubmitFormCancelReservation)}>
          {!shopSetting?.hideCancelReason && (
            <div css={commonStyles.inputContainer}>
              <div css={styles.inputTitle}>
                キャンセル理由
                <span css={styles.required}>*</span>
              </div>
              <input
                name="reason"
                type="text"
                value={reason}
                onChange={handleChangeReason}
                required
                ref={(ref) => ref?.focus()}
                css={styles.inputField}
              />
            </div>
          )}
          <div style={{ marginTop: '15px' }}>
            <button
              type="submit"
              disabled={disabledSubmit}
              css={styles.reserveCancelButton}
              style={{
                background: primaryColor,
              }}
            >
              <span style={{ marginRight: '8px' }}>{buildLoading()}</span>
              予約をキャンセルする
            </button>
          </div>
        </form>
      </div>
    );
  };

  return <div>{buildCancelReservationForm()}</div>;
}
