import axios, { AxiosResponse } from 'axios';
import { useEffect, useState } from 'react';
import { ResponseBody } from '../../../frontend-api/src/handlers/public/public-course-handler';
import { Course } from '../@interfaces/course';
import { Shop } from '../@interfaces/shop';
import { PublicShopSetting } from '../@interfaces/shop-setting';
import { DisplayMode } from '../@interfaces/course-slot-setting';
import { Workspace } from '../@interfaces/workspace';
import { PublicWorkspaceSetting } from '../@interfaces/workspace-setting';
import { API_END_POINT } from './api';

export default function useCourse(
  workspaceUid: string,
  shopUid: string,
  courseUid: string
) {
  const [workspace, setWorkspace] = useState<Workspace>();
  const [workspaceSetting, setWorkspaceSetting] =
    useState<PublicWorkspaceSetting>();
  const [shop, setShop] = useState<Shop>();
  const [shopSetting, setShopSetting] = useState<PublicShopSetting>();
  const [course, setCourse] = useState<Course>();
  const [loading, setLoading] = useState(true);
  const [displayMode, setDisplayMode] = useState<DisplayMode>();
  useEffect(() => {
    setLoading(true);
    axios
      .get(
        `${API_END_POINT}/public/ws/${workspaceUid}/shops/${shopUid}/courses/${courseUid}`
      )
      .then((response: AxiosResponse<ResponseBody>) => {
        setWorkspace(response.data.workspace);
        setWorkspaceSetting(response.data.workspaceSetting);
        setShop(response.data.shop);
        setShopSetting(response.data.shopSetting);
        setCourse(response.data.course);
        setDisplayMode(response.data.displayMode);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [workspaceUid, shopUid, courseUid]);
  return {
    workspace,
    workspaceSetting,
    shop,
    shopSetting,
    course,
    displayMode,
    isLoadingCourse: loading,
  };
}
