import React from 'react';
import sanitizeHtml from 'sanitize-html';

export const lineBreakToParagraph = (str: string) =>
  str.split('\n').map((line) => <p>{line}</p>);

export const sanitize = (str: string) => {
  return sanitizeHtml(str, {
    allowedTags: ['img', 'a', 'span', 'table', 'tr', 'th', 'td'],
    allowedAttributes: {
      img: ['src', 'width', 'height', 'style'],
      a: ['href', 'target', 'style'],
      span: ['style'],
      table: ['style'],
      tr: ['style'],
      th: ['style'],
      td: ['style'],
    },
    parseStyleAttributes: false,
  });
};

export const lineBreakToParagraphAndHtml = (str: string) =>
  normalize(str)
    .split('\n')
    .map((line, index) => (
      <p
        dangerouslySetInnerHTML={{ __html: line ? sanitize(line) : '&nbsp;' }}
        key={`line${index}`}
      />
    ));

/**
 * レンダリングに不要なホワイトスペースや改行を取り除きます。
 * - テーブルタグ内部の改行を全て除去します。
 * @param str
 */
export const normalize = (html: string): string => {
  // テーブルタグ内を探す正規表現パターン
  const tablePattern = /(<table[^>]*>)([\s\S]*?)(<\/table>)/gim;

  // テーブルタグ内部の改行を全て除去する
  return html.replace(
    tablePattern,
    (match, p1: string, p2: string, p3: string) => {
      return `${p1}${p2.replace(/\r?\n|\r/g, '')}${p3}`;
    }
  );
};

export const lineBreakToBrAndHtml = (str: string) =>
  str.split('\n').map((line, index) => (
    <span key={`line${index}`}>
      <span
        dangerouslySetInnerHTML={{ __html: line ? sanitize(line) : '&nbsp;' }}
      />
      <br />
    </span>
  ));

export const copyToClipborad = (text: string) => {
  const tmp = document.createElement('div');
  const pre = document.createElement('pre');
  pre.style.webkitUserSelect = 'auto';
  pre.style.userSelect = 'auto';
  tmp.appendChild(pre).textContent = text;

  const s = tmp.style;
  s.position = 'fixed';
  s.right = '200%';

  document.body.appendChild(tmp);
  (document as any).getSelection().selectAllChildren(tmp);
  const result = document.execCommand('copy');
  document.body.removeChild(tmp);
};

/**
 * childの親にtargetが含まれるかどうかを判定します
 * @param child 子要素
 * @param target 対象要素
 */
export const isParent = (child: Element, target: Element): boolean => {
  const parent = child.parentElement;
  if (!parent) {
    return false;
  } else if (target == parent) {
    return true;
  } else {
    return isParent(parent, target);
  }
};

export interface HTMLElementEvent<T extends HTMLElement> extends Event {
  target: T;
}

export const cloneElement = (srcElem: Element): HTMLElement => {
  const newElem = document.createElement(srcElem.tagName);
  for (const attr of Array.from(srcElem.attributes)) {
    newElem.setAttribute(attr.name, attr.value);
  }
  newElem.innerHTML = srcElem.innerHTML;
  return newElem;
};

export const cloneElements = (srcElems: Element[]): HTMLElement[] => {
  return Array.from(srcElems).map(cloneElement);
};

export const cloneHtmlCollection = (
  srcElems: HTMLCollection
): HTMLElement[] => {
  return cloneElements(Array.from(srcElems));
};

export const insertUserScripts = (
  userScript: string,
  position: 'head_last' | 'body_first'
) => {
  try {
    const parent = document.createElement('div');
    parent.innerHTML = userScript;
    const newElems = cloneHtmlCollection(parent.children);
    for (const elem of newElems) {
      if (position == 'head_last') {
        document.head.appendChild(elem);
      } else if (position == 'body_first') {
        document.body.prepend(elem);
      }
    }
  } catch (e) {
    console.error(`Can't insert user scripts`, e, userScript);
  }
};

export const toDoubleDigits = (num: number): string => {
  let number = `${num}`;
  number += '';
  if (number.length === 1) {
    number = '0' + number;
  }
  return number;
};
