import { css } from '@emotion/react';

export const holiday = css({
  color: 'red',
});

export const workday = css({
  color: 'black',
});

export const saturday = css({
  color: 'blue',
});

export const commonStyles = {
  dayOfWeek: {
    0: holiday,
    1: workday,
    2: workday,
    3: workday,
    4: workday,
    5: workday,
    6: saturday,
  },
};

export const styles = {
  info: css({
    margin: '10px 20px',
    fontSize: '16px',
  }),
  inputContainer: css({
    paddingBottom: '5px',
    display: 'flex',
    flexDirection: 'column',
    '.field-label': css({
      fontSize: '14px',
    }),
    '&:focus-within .field-label': css({
      color: '#0063DA',
    }),
    'label.option-label': css({
      fontSize: '14px',
    }),
    'input[type=text],input[type=email],input[type=tel],input[type=number]':
      css({
        padding: '10px',
        fontSize: '20px',
        width: '100%',
        border: '1px solid #cdcdcd',
        borderRadius: '5px 5px',
      }),
    textarea: css({
      padding: '10px',
      fontSize: '20px',
      width: '100%',
      border: '1px solid #cdcdcd',
      borderRadius: '5px 5px',
    }),
  }),
  description: css({
    color: '#777',
    fontSize: '10px',
  }),
  required: css({
    marginLeft: '5px',
  }),
  error: css({
    color: 'red',
    fontSize: '14px',
  }),
};
