import dayjs from 'dayjs';
import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

/**
 * 予約日時変更画面のURLパラメータを正規化する
 * 予約日時が366日以上の場合は1年に制限する
 * @param searchParams URLSearchParams
 */
export const useNormalizeLocationUrl = (
  searchParams: URLSearchParams
): void => {
  const history = useHistory();
  // 初回表示時のみ実行
  useEffect(() => {
    const startDate = searchParams.get('startDate');
    const endDate = searchParams.get('endDate');
    if (startDate && endDate && dayjs(endDate).diff(startDate, 'days') > 366) {
      history.replace(`${window.location.pathname}?startDate=${startDate}`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};
