/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';
import React from 'react';
import { TextareaField } from '../../../../../core/types/reservation-form-types';
import { FieldErrorMessage } from '../FieldErrorMessage';
import { BaseFormFieldProps } from './BaseFormField';
import { FieldDescription } from './FieldDescription';
import { FieldLabel } from './FieldLabel';
import { buildFieldValidation } from './input-helpers';
import { styles as inputStyles } from './input-styles';

const styles = {
  ...inputStyles,
  textarea: css`
    // Chromeのデフォルトスタイルをリセットして他のinput要素と同じにする
    font-family: Arial;
  `,
};

type TextareaFormFieldProps = BaseFormFieldProps & {
  field: TextareaField;
};
export function TextareaFormField(props: TextareaFormFieldProps) {
  const {
    field,
    fieldResponse,
    onChangeValue,
    validationContext,
    primaryColor,
  } = props;
  const { errors, register, trigger } = validationContext;

  const fieldValidation = buildFieldValidation(field);

  const handleChangeValue = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const newValue = e.target.value;
    onChangeValue?.(field, [newValue]);
  };

  const fieldError = errors[field.uid];

  return (
    <div>
      <div css={styles.inputLabel}>
        <div css={styles.inputTitle}>
          <FieldLabel field={field} />
        </div>
        <FieldErrorMessage field={field} errors={errors} />
      </div>
      <FieldDescription description={field.description} />
      <textarea
        name={field.uid}
        value={fieldResponse.values[0] as string}
        onChange={handleChangeValue}
        ref={register(fieldValidation)}
        css={[
          styles.inputField,
          styles.textarea,
          fieldError && styles.inputErrorField,
        ]}
        onBlur={async () => await trigger(field.uid)}
      ></textarea>
    </div>
  );
}
