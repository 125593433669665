/** @jsxImportSource @emotion/react */

import React from 'react';
import { NumberField } from '../../../../../core/types/reservation-form-types';
import { FieldErrorMessage } from '../FieldErrorMessage';
import { BaseFormFieldProps } from './BaseFormField';
import { FieldDescription } from './FieldDescription';
import { FieldLabel } from './FieldLabel';
import { buildFieldValidation } from './input-helpers';
import { styles } from './input-styles';

type NumberFormFieldProps = BaseFormFieldProps & {
  field: NumberField;
};
export function NumberFormField(props: NumberFormFieldProps) {
  const {
    field,
    fieldResponse,
    onChangeValue,
    validationContext,
    primaryColor,
  } = props;
  const { errors, register, trigger } = validationContext;

  const fieldValidation = buildFieldValidation(field);

  const handleChangeValue = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    onChangeValue?.(field, [newValue]);
  };

  const fieldError = errors[field.uid];

  return (
    <div>
      <div css={styles.inputLabel}>
        <div css={styles.inputTitle}>
          <FieldLabel field={field} />
        </div>
        <FieldErrorMessage field={field} errors={errors} />
      </div>
      <FieldDescription description={field.description} />
      <input
        type="number"
        name={field.uid}
        value={fieldResponse.values[0] as string}
        inputMode="numeric"
        onChange={handleChangeValue}
        ref={register(fieldValidation)}
        css={[styles.inputField, fieldError && styles.inputErrorField]}
        onBlur={async () => await trigger(field.uid)}
      />
    </div>
  );
}
