import axios, { AxiosResponse } from 'axios';
import { useCallback, useState } from 'react';
import { ResponseBody } from '../../../frontend-api/src/handlers/public/public-monthly-reservations-handler';
import { ReservationTable } from '../core/types/reservation-types';
import { API_END_POINT } from './api';

export default function useMonthlyReservations(
  workspaceUid: string,
  shopUid: string,
  courseUid: string
) {
  const [monthlyReservations, setMonthlyReservations] =
    useState<ReservationTable>();
  const [loading, setLoading] = useState(false);

  const fetchMonthlyReservations = useCallback(
    async (startDate: string) => {
      const params = {
        startDate: startDate,
      };
      setLoading(true);
      const response: AxiosResponse<ResponseBody> = await axios.get(
        `${API_END_POINT}/public/ws/${workspaceUid}/shops/${shopUid}/courses/${courseUid}/monthly-reservations`,
        { params }
      );
      setMonthlyReservations(response.data.monthlyReservations);
      setLoading(false);
    },
    [courseUid, shopUid, workspaceUid]
  );

  return {
    isLoadingMonthlyReservations: loading,
    setLoading,
    monthlyReservations,
    fetchMonthlyReservations,
  };
}
