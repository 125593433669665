/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';
import React from 'react';
import CalendarInfoImg from './calendarInfo.png';
import CalendarInfoForWidgetModeImg from './calendarInfoForWidgetMode.png';
import Close from './close.svg';

const styles = {
  modalLayer: css`
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.2);
  `,
  modalContent: css`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: calc(100% - 32px);
    max-width: 350px;
    padding: 40px 24px;
    border: 2px solid #172b4d;
    background: #f2f2f2;
    border-radius: 10px;
  `,
  modalContentImg: css`
    width: 100%;
  `,
  closeButton: css`
    position: absolute;
    right: 16px;
    bottom: 16px;
    border: none;
    background: #172B4D;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    cursor: pointer;
    :hover {
      opacity: 0.8;
    },
  `,
  closeButtonImg: css`
    display: block;
    margin: 0 auto;
    width: 14px;
  `,
};

type CalendarInfoDialogProps = {
  primaryColor: string;
  widgetMode: boolean;
  onClickCloseButton: () => void;
};

/**
 * カレンダーの説明ダイアログ
 */
export const CalendarInfoDialog = ({
  primaryColor,
  widgetMode,
  onClickCloseButton,
}: CalendarInfoDialogProps): JSX.Element => {
  return (
    <div css={styles.modalLayer} onClick={onClickCloseButton}>
      <div
        css={styles.modalContent}
        style={{ borderColor: primaryColor }}
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <div>
          <img
            css={styles.modalContentImg}
            src={widgetMode ? CalendarInfoForWidgetModeImg : CalendarInfoImg}
            alt="info"
          />
        </div>
        <button
          css={styles.closeButton}
          style={{ background: primaryColor }}
          onClick={onClickCloseButton}
        >
          <img css={styles.closeButtonImg} src={Close} alt="close" />
        </button>
      </div>
    </div>
  );
};
