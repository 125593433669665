/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';
import {
  CourseSlotSetting,
  DisplayMode,
  ShopCourseSetting,
} from '../../../@interfaces/course-slot-setting';
import {
  DateRange,
  ReservationTable as IReservationTable,
} from '../../../core/types/reservation-types';
import ReservationTable from './ReservationTable';
import ReservationTableVer1 from './ReservationTableVer1';

export type CourseSlotSettingForReservationTable = Pick<
  CourseSlotSetting,
  'showsSlotCapacity' | 'hidesNoSlotDate'
> & {
  shopCourseSetting?: Pick<ShopCourseSetting, 'minutesRequired'>;
};

type ReservationTableContentProps = {
  courseSlotSetting: CourseSlotSettingForReservationTable | undefined;
  reservationTable: IReservationTable | undefined;
  dateRange: DateRange;
  primaryColor: string;
  widgetMode: boolean;
  displayMode: DisplayMode | undefined;
  // プレビューの場合はtrue
  previewMode?: boolean;
  // 週の移動を行った場合に呼ばれます(プレビューモード時に使用)
  onChangeDateRange?: (dateRange: DateRange) => void;
};

/**
 * 予約枠テーブルを表示種別を判定して表示
 * - displayModeがdefaultの場合はReservationTableを表示
 * - displayModeがver1の場合はReservationTableVer1を表示
 */
export const ReservationTableContent = ({
  courseSlotSetting,
  reservationTable,
  dateRange,
  primaryColor,
  widgetMode,
  displayMode,
  previewMode,
  onChangeDateRange,
}: ReservationTableContentProps): JSX.Element => {
  return (
    <div
      css={css`
        min-height: 200px;
      `}
    >
      {displayMode === 'default' ? (
        <ReservationTable
          dateRange={dateRange}
          reservationTable={reservationTable}
          primaryColor={primaryColor}
          courseMinutesRequired={
            courseSlotSetting?.shopCourseSetting?.minutesRequired
          }
          isNoData={!reservationTable}
          showsSlotCapacity={!!courseSlotSetting?.showsSlotCapacity}
          widgetMode={widgetMode}
          hidesNoSlotDate={courseSlotSetting?.hidesNoSlotDate || false}
          previewMode={previewMode}
          onChangeDateRange={onChangeDateRange}
        />
      ) : (
        <ReservationTableVer1
          dateRange={dateRange}
          reservationTable={reservationTable}
          primaryColor={primaryColor}
          showsSlotCapacity={!!courseSlotSetting?.showsSlotCapacity}
          widgetMode={widgetMode}
          previewMode={previewMode}
          onChangeDateRange={onChangeDateRange}
        />
      )}
    </div>
  );
};
