/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';
import React, { useRef, useState } from 'react';
import ReactLoading from 'react-loading';
import { DateTime, toDayjsByDateTime } from '../core/types/reservation-types';
import { LOGO_BASE_URL } from '../models/theme';

type PageParams = {
  workspaceUid: string;
  shopUid: string;
};

type HeaderProps = {
  logoPath: string | undefined;
  shopName: string | undefined;
  primaryColor: string;
};

export function Header(props: HeaderProps) {
  const { logoPath, shopName, primaryColor } = props;
  const imageElement = useRef<HTMLImageElement>(null);
  const [imageWidth, setImageWidth] = useState<number>(0);

  const handleImageLoad = () => {
    setImageWidth(imageElement.current ? imageElement.current.clientWidth : 0);
  };

  const styles = {
    header: css`
      padding: 10px;
    `,
    logo: css`
      display: inline-block;
      position: relative;
      vertical-align: middle;
      height: 40px;
      margin-right: 16px;
      overflow: hidden;
      & img {
        height: 100%;
      }
    `,
    shopName: css`
      display: inline-block;
      vertical-align: middle;
      font-size: 16px;
      font-weight: 600;
      color: #172b4d;
      margin: 0 auto;
      padding: 0;
      width: calc(100% - 20px - 16px - ${imageWidth}px);
    `,
    pointer: css`
      cursor: pointer;
    `,
  };

  return (
    <header css={styles.header}>
      <div>
        {logoPath && (
          <div css={styles.logo}>
            <img
              src={`${LOGO_BASE_URL}/${logoPath}`}
              ref={imageElement}
              onLoad={handleImageLoad}
              alt=""
            />
          </div>
        )}
        <h2
          css={[
            styles.shopName,
            css`
              color: ${primaryColor};
            `,
          ]}
        >
          {shopName}
        </h2>
      </div>
    </header>
  );
}

export function Loading(props: { primaryColor: string }) {
  const { primaryColor } = props;
  return (
    <div
      css={css`
        position: relative;
        width: 100%;
        padding: 30px 20px;
      `}
    >
      <div
        css={css`
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          text-align: center;
        `}
      >
        <ReactLoading
          type="spokes"
          color={primaryColor}
          height={'30px'}
          width={'30px'}
        />
      </div>
      <div
        css={css`
          position: absolute;
          top: calc(50% + 30px);
          left: 50%;
          transform: translate(-50%, -50%);
          text-align: center;
          font-weight: bold;
          font-size: 14px;
        `}
      >
        読込中...
      </div>
    </div>
  );
}

export function SelectedReserveInfo(props: {
  name: string;
  imagePath: string | null;
  dateTime: DateTime;
  children?: React.ReactNode;
  primaryColor: string;
  requiredMinutes?: number | false;
}) {
  const { name, imagePath, dateTime, children, primaryColor, requiredMinutes } =
    props;

  const styles = {
    selectedReserve: css`
      background: #ffffff;
      margin: 24px auto;
      padding: 12px;
      border-radius: 10px;
    `,
    reserveCourseName: css`
      margin-bottom: 12px;
      font-weight: bold;
      font-size: 14px;
      color: ${primaryColor};
    `,
    reserveInfoWrapper: css`
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
    `,
    courseImageWrapper: css`
      position: relative;
      width: 100px;
      height: 100px;
      overflow: hidden;
      & img {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        height: 100px;
      }
    `,
    reserveDateWrapper: css`
      display: flex;
      justify-content: flex-start;
      align-items: center;
    `,
    reserveDate: css`
      color: #ff0089;
      font-size: 17px;
      font-weight: bold;
    `,
    reserveTimeWrapper: css`
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 8px;
      font-size: 20px;
      font-weight: bold;
      color: #172b4d;
    `,
  };

  return (
    <div css={styles.selectedReserve}>
      <div css={styles.reserveInfoWrapper}>
        {imagePath != null && (
          <div css={styles.courseImageWrapper}>
            <img src={LOGO_BASE_URL + '/' + imagePath} alt="" />
          </div>
        )}
        <div
          css={css`
            margin-left: 12px;
            width: calc(100% - 112px);
          `}
        >
          <div>
            <p css={styles.reserveCourseName}>{name}</p>
          </div>
          <div css={styles.reserveDateWrapper}>
            <p css={styles.reserveDate}>
              {toDayjsByDateTime(dateTime)
                .locale('ja')
                .format('YYYY年M月D日 (dd)')}
            </p>
          </div>
          <div css={styles.reserveTimeWrapper}>
            <p>
              {toDayjsByDateTime(dateTime).locale('ja').format('HH:mm')}
              <span
                css={css`
                  margin-left: 8px;
                `}
              >
                〜
              </span>
            </p>
            {requiredMinutes && <p>所要時間(目安): {requiredMinutes}分</p>}
          </div>
          {children}
        </div>
      </div>
    </div>
  );
}
