/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';
import React, { useState } from 'react';

const styles = {
  privacyLink: css({
    display: 'block',
    width: 'fit-content',
    margin: '32px auto 0',
    fontSize: '14px',
    cursor: 'pointer',
  }),
};

type PrivacyPolicyProps = {
  privacyPolicy: string;
  primaryColor: string;
};

export function PrivacyPolicy({
  privacyPolicy,
  primaryColor,
}: PrivacyPolicyProps): JSX.Element | null {
  const [open, setOpen] = useState(false);
  const handleClickOpen = (event: React.MouseEvent) => {
    event.preventDefault();
    setOpen(!open);
  };

  if (!privacyPolicy) {
    return null;
  }

  const isUrl = /^https?:\/\/[-_.!~*\\'()a-zA-Z0-9;\\/?:\\@&=+\\$,%#]+$/.test(
    privacyPolicy
  );
  if (isUrl) {
    return (
      <div>
        <a
          href={privacyPolicy}
          target="_blank"
          rel="noreferrer"
          css={styles.privacyLink}
          style={{ color: primaryColor }}
        >
          プライバシーポリシー
        </a>
      </div>
    );
  } else {
    return (
      <div>
        <div className="privacy-policy-container">
          <div>
            <button
              css={styles.privacyLink}
              style={{ color: primaryColor }}
              onClick={handleClickOpen}
            >
              プライバシーポリシー
            </button>
          </div>
          <div>
            {open ? <textarea readOnly>{privacyPolicy}</textarea> : null}
          </div>
        </div>
      </div>
    );
  }
}
