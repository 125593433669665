/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';

export const styles = {
  fieldPaper: css({
    borderRadius: '10px',
    background: '#FFFFFF',
    padding: '20px 0',
    marginTop: '10px',
    boxShadow: '0 3px 6px rgba(44,40,40,0.11)',
  }),
  customLabel: css({
    cursor: 'pointer',
  }),
  customLabelText: css({
    display: 'inline-block',
    width: 'calc(100% - 40px)',
    verticalAlign: 'middle',
    marginLeft: '20px',
    fontSize: '16px',
  }),
  questionText: css({
    fontSize: '14px',
    fontWeight: 'bold',
    paddingLeft: '12px',
    paddingBottom: '8px',
  }),
  inputErrorField: css({
    border: '1px solid #F53C56 !important',
  }),
};
