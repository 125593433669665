/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';

export const styles = {
  inputLabel: css({
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    paddingBottom: '8px',
    marginTop: '16px',
  }),
  inputTitle: css({
    paddingLeft: '16px',
    fontSize: '14px',
    fontWeight: 'bold',
  }),
  inputField: css({
    width: '100%',
    fontSize: '16px',
    border: '1px solid #C5C9D1',
    borderRadius: '10px',
    padding: '12px',
  }),
  inputErrorField: css({
    border: '1px solid #F53C56 !important',
  }),
};
