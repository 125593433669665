// コンテンツの高さとiframeの高さが同一値の場合
// スクロールバーが表示される場合があるのでバッファの高さを追加する
const IFRAME_HEIGHT_BUFFER = 1;

const sendChangeEvent = () => {
  window.parent.postMessage(
    {
      type: 'locaop-reservation-iframe-height',
      height: document.documentElement.offsetHeight + IFRAME_HEIGHT_BUFFER,
    },
    '*'
  );
};

export const setupWidgetMode = () => {
  if (window.parent === window) {
    return;
  }
  const resizeObserver = new ResizeObserver(sendChangeEvent);
  resizeObserver.observe(document.documentElement);
};

export const sendShowCourseEventToWidget = () => {
  if (window.parent === window) {
    return;
  }
  window.parent.postMessage(
    {
      type: 'locaop-reservation-show-course-page',
      height: document.documentElement.offsetHeight + IFRAME_HEIGHT_BUFFER,
    },
    '*'
  );
};

export const sendClickCourseEventToWidget = () => {
  if (window.parent === window) {
    return;
  }
  window.parent.postMessage(
    {
      type: 'locaop-reservation-click-course-page',
    },
    '*'
  );
};

export const sendShowFormEventToWidget = () => {
  if (window.parent === window) {
    return;
  }
  window.parent.postMessage(
    {
      type: 'locaop-reservation-show-form-page',
      height: document.documentElement.offsetHeight + IFRAME_HEIGHT_BUFFER,
    },
    '*'
  );
};

export const sendShowThanksEventToWidget = () => {
  if (window.parent === window) {
    return;
  }
  window.parent.postMessage(
    {
      type: 'locaop-reservation-show-thanks-page',
      height: document.documentElement.offsetHeight + IFRAME_HEIGHT_BUFFER,
    },
    '*'
  );
};
