import { Shop } from '../../../@interfaces/shop';
import { PublicShopSetting } from '../../../@interfaces/shop-setting';
import { PublicWorkspaceSetting } from '../../../@interfaces/workspace-setting';
import { Header } from '../../../components/Shared';

type HeaderContentProps = {
  shop: Shop | undefined;
  shopSetting: PublicShopSetting | undefined;
  workspaceSetting: PublicWorkspaceSetting | undefined;
  primaryColor: string;
  widgetMode: boolean;
};

/**
 * ヘッダーの表示
 */
export const HeaderContent = ({
  shop,
  shopSetting,
  workspaceSetting,
  primaryColor,
  widgetMode,
}: HeaderContentProps): JSX.Element | null => {
  if (widgetMode) {
    return null;
  }
  return (
    <Header
      logoPath={shopSetting?.logoPath || workspaceSetting?.logoPath}
      shopName={shop?.name || ''}
      primaryColor={primaryColor}
    />
  );
};
