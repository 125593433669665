import axios from 'axios';
import { useEffect } from 'react';
import { PublicConversionFunnelCourseRequestData } from '../../../@interfaces/insight/conversion-funnel-api';
import { API_END_POINT } from '../../../api/api';
import { getConversionUuid } from '../utils/conversionUuid';

export const trackConversionFunnelCourse = async (
  workspaceUid: string,
  shopUid: string,
  courseUid: string,
  data: PublicConversionFunnelCourseRequestData
): Promise<void> => {
  await axios.post(
    `${API_END_POINT}/public/ws/${workspaceUid}/shops/${shopUid}/courses/${courseUid}/conversion-funnel-course`,
    data
  );
};

export const useConversionFunnelCourse = (
  workspaceUid: string | undefined,
  shopUid: string | undefined,
  inflowSource: string | null,
  courseUid: string | undefined,
  pageType: PublicConversionFunnelCourseRequestData['pageType'],
  shouldTrack = true
) => {
  useEffect(() => {
    try {
      if (shouldTrack && workspaceUid && shopUid && courseUid) {
        const data: PublicConversionFunnelCourseRequestData = {
          conversionUuid: getConversionUuid(),
          inflowSource,
          pageType,
        };
        void trackConversionFunnelCourse(
          workspaceUid,
          shopUid,
          courseUid,
          data
        );
      }
    } catch (e) {
      console.error(e);
    }
  }, [courseUid, inflowSource, pageType, shopUid, shouldTrack, workspaceUid]);
};
