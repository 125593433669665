export type SpecialFieldType = 'tel' | 'email' | 'name';
export type StandardFieldType =
  | 'text'
  | 'textarea'
  | 'radio'
  | 'checkbox'
  | 'number';
export type FieldType = SpecialFieldType | StandardFieldType;

export type UID = string;

export type Option = {
  uid: UID;
  text: string;
};

export type FieldCondition = {
  public: {
    visibled: boolean;
    required: boolean;
  };
  internal: {
    required: boolean;
  };
};

export type Field = {
  uid: UID;
  type: FieldType;
  name: string;
  description?: string;
  defaultValues?: string[];
  condition?: FieldCondition;
};

export const createDefaultFieldCondition = (): FieldCondition => {
  return {
    public: {
      visibled: true,
      required: true,
    },
    internal: {
      required: false,
    },
  };
};

export const defaultFieldCondition: FieldCondition =
  createDefaultFieldCondition();

export const publicFields = (fields: AnyField[]) => {
  return fields.filter((f) => {
    return f.condition == undefined || f.condition.public.visibled;
  });
};

export type TextField = Field & {
  type: 'text';
};

export type TextareaField = Field & {
  type: 'textarea';
};

export type RadioField = Field & {
  type: 'radio';
  options: Option[];
  isShowPulldown?: boolean;
};

export type CheckboxField = Field & {
  type: 'checkbox';
  options: Option[];
};

export type TelField = Field & {
  type: 'tel';
};

export type EmailField = Field & {
  type: 'email';
};

export type NameField = Field & {
  type: 'name';
};

export type NumberField = Field & {
  type: 'number';
};

export type AnyField =
  | TextField
  | TextareaField
  | RadioField
  | CheckboxField
  | TelField
  | EmailField
  | NameField
  | NumberField;

export type FormSetting = {
  fields: AnyField[];
  deletedFields?: AnyField[];
};

export type OptionValue = {
  uid: UID;
};

export const isOptionValue = (value: any): value is OptionValue => {
  return typeof value.uid === 'string';
};

export type FieldResponseValue = string | OptionValue;

export type FieldResponse = {
  uid: UID;
  values: FieldResponseValue[];
};

export type FormResponse = {
  fields: FieldResponse[];
};

export const isSpecialFieldType = (type: FieldType) =>
  type == 'email' || type == 'tel' || type == 'name';

export const getSpecialFieldValue = (
  formSetting: FormSetting,
  formResponse: FormResponse,
  fieldType: SpecialFieldType
): string | undefined => {
  const field = formSetting.fields.find((f) => f.type == fieldType);
  if (!field) {
    return undefined;
  }
  const fieldResponse = formResponse.fields.find((f) => f.uid == field.uid);
  const fieldValue = fieldResponse?.values?.[0];
  if (!fieldValue) {
    return undefined;
  }
  if (typeof fieldValue === 'string') {
    return fieldValue;
  } else if (field.type === 'checkbox' || field.type === 'radio') {
    return field.options.find((o) => o.uid === fieldValue.uid)?.text;
  } else {
    return undefined;
  }
};

export const createDefaultFieldResponses = (
  formSetting: FormSetting | undefined
): FieldResponse[] => {
  if (!formSetting?.fields) {
    return [];
  }
  const defaultFieldValues = formSetting.fields
    .filter((f) => {
      return f.defaultValues && f.defaultValues.length > 0;
    })
    .map((f) => {
      return {
        uid: f.uid,
        values: [...(f.defaultValues || [])],
      } as FieldResponse;
    });
  return defaultFieldValues;
};
