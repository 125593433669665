/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';
import React, { useState } from 'react';
import { CalendarInfoDialog } from '../CalendarInfoDialog/CalendarInfoDialog';
import Question from './question.svg';

const styles = {
  calendarInfoButton: css`
    border: none;
    color: #172b4d;
    border-bottom: 1px solid transparent;
    cursor: pointer;
    span,
    img {
      vertical-align: middle;
    }
    &:hover {
      border-bottom: 1px solid #172b4d;
    }
  `,
};

type CalendarInfoButtonProps = {
  primaryColor: string;
  widgetMode: boolean;
};

/**
 * カレンダー表記について表示するボタン
 */
export const CalendarInfoButton = ({
  primaryColor,
  widgetMode,
}: CalendarInfoButtonProps): JSX.Element => {
  const [isShowCalendarInfoDialog, setIsShowCalendarInfoDialog] =
    useState(false);

  const handleClickOpenButton = () => {
    setIsShowCalendarInfoDialog(true);
  };

  const handleClickCloseButton = () => {
    setIsShowCalendarInfoDialog(false);
  };

  return (
    <>
      <button css={styles.calendarInfoButton} onClick={handleClickOpenButton}>
        <span>カレンダー表記について</span>
        <img
          src={Question}
          alt="icon"
          css={css`
            margin-left: 4px;
          `}
        />
      </button>
      {isShowCalendarInfoDialog && (
        <CalendarInfoDialog
          primaryColor={primaryColor}
          widgetMode={widgetMode}
          onClickCloseButton={handleClickCloseButton}
        />
      )}
    </>
  );
};
