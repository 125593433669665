/** @jsxImportSource @emotion/react */

import React, { useEffect, useMemo, useState } from 'react';
import { FieldValues, UseFormMethods } from 'react-hook-form';
import { CustomerLastInputOverview } from '../../../../@interfaces/customer-last-input';
import {
  AnyField,
  Field,
  FieldResponseValue,
  FormResponse,
  publicFields,
} from '../../../../core/types/reservation-form-types';
import { CustomerLastInputFields } from './CustomerLastInputFields';
import { FormField } from './input/FormField';

type FormFieldsProps = {
  fields: AnyField[];
  formResponse: FormResponse;
  primaryColor: string;
  validationContext: UseFormMethods;
  onChangeValue?: (field: Field, newValues: FieldResponseValue[]) => void;
  onChangeValues?: (
    fields: {
      field: Field;
      newValues: FieldResponseValue[];
    }[]
  ) => void;
  customerLastInput?: CustomerLastInputOverview;
};

export function FormFields({
  fields,
  formResponse,
  primaryColor,
  validationContext,
  onChangeValue,
  onChangeValues,
  customerLastInput,
}: FormFieldsProps) {
  const [useCustomerLastInput, setUseCustomerLastInput] = useState<
    undefined | boolean
  >(undefined);

  useEffect(() => {
    if (!customerLastInput || !onChangeValues) {
      return;
    }
    if (useCustomerLastInput === undefined) {
      setUseCustomerLastInput(true);
      // 前回入力値からフォームの値を設定
      const pubFields = publicFields(fields);
      const nameField = pubFields.find((f) => f.type === 'name');
      const telField = pubFields.find((f) => f.type === 'tel');
      const emailField = pubFields.find((f) => f.type === 'email');
      const values: { field: Field; newValues: FieldResponseValue[] }[] = [];
      if (nameField && customerLastInput.name) {
        values.push({
          field: nameField,
          newValues: [customerLastInput.name],
        });
      }
      if (telField && customerLastInput.tel) {
        values.push({ field: telField, newValues: [customerLastInput.tel] });
      }
      if (emailField && customerLastInput.email) {
        values.push({
          field: emailField,
          newValues: [customerLastInput.email],
        });
      }
      onChangeValues(values);
    }
  }, [customerLastInput, fields, onChangeValues, useCustomerLastInput]);

  const pubFields = useMemo(
    () =>
      publicFields(fields)
        .filter((field) => {
          // 前回入力値を使う場合は、前回入力値がある場合は入力欄を表示しない
          if (!useCustomerLastInput || !customerLastInput) {
            return true;
          } else if (
            (field.type === 'name' && customerLastInput.name) ||
            (field.type === 'email' && customerLastInput.email) ||
            (field.type === 'tel' && customerLastInput.tel)
          ) {
            return false;
          } else {
            return true;
          }
        })
        .map((field, index) => {
          return (
            <div key={`field${index}`}>
              <FormField
                field={field}
                formResponse={formResponse}
                primaryColor={primaryColor}
                validationContext={validationContext}
                onChangeValue={onChangeValue}
                key={index}
              />
            </div>
          );
        }),
    [
      fields,
      useCustomerLastInput,
      customerLastInput,
      formResponse,
      primaryColor,
      validationContext,
      onChangeValue,
    ]
  );

  const handleCancelLastInput = () => {
    setUseCustomerLastInput(false);
  };

  return (
    <div>
      {useCustomerLastInput ? (
        <CustomerLastInputFields
          fields={fields}
          customerLastInput={customerLastInput}
          primaryColor={primaryColor}
          onCancelLastInput={handleCancelLastInput}
        />
      ) : null}
      {pubFields}
    </div>
  );
}
