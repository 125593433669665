/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';
import { Shop } from '../../../@interfaces/shop';
import { PublicWorkspaceSetting } from '../../../@interfaces/workspace-setting';

type Props = {
  shops: Shop[];
  workspaceSetting: PublicWorkspaceSetting | undefined;
  primaryColor: string;
  handleClickShop: (shop: Shop) => void;
};

const styles = {
  container: css({
    background: '#f2f2f2',
  }),
  shop: css({
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    fontSize: '16px',
    lineHeight: '150%',
    padding: '18px 20px',
    fontWeight: 'bold',
    borderBottom: '2px solid #ffffff',
    cursor: 'pointer',
  }),
};

export default function Shops(props: Props) {
  const { shops, workspaceSetting, primaryColor, handleClickShop } = props;

  const handleClick = (shop: Shop) => {
    handleClickShop(shop);
  };

  return (
    <div css={styles.container}>
      {shops.map((shop) => {
        return (
          <div
            key={shop.id}
            onClick={() => {
              handleClick(shop);
            }}
            css={styles.shop}
          >
            {shop.name}
          </div>
        );
      })}
    </div>
  );
}
