import axios, { AxiosResponse } from 'axios';
import { useEffect, useState } from 'react';
import { ResponseBody } from '../../../frontend-api/src/handlers/public/public-reservation-handler';
import { INFLOW_SOURCE_UNKNOWN } from '../@interfaces/reservation';
import { ReservationResourceWithResource } from '../@interfaces/resource/reservation-resource';
import { FormResponse } from '../core/types/reservation-form-types';
import { ResourceResponse } from '../core/types/reservation-resource-types';
import { DateTime } from '../core/types/reservation-types';
import { LineUserChannelInfo } from '../models/line';
import { getCustomerUUID } from '../utils/logging';
import { API_END_POINT } from './api';

export default function useReservation(
  workspaceUid: string,
  shopUid: string,
  courseUid: string,
  reservationKey: string | null
) {
  const [
    canUpdateReservationDateTimeFromPublic,
    setCanUpdateReservationDateTimeFromPublic,
  ] = useState(true);
  const [isCanceled, setCanceled] = useState(false);

  const [originalDateTime, setOriginalDateTime] = useState<
    DateTime | undefined
  >(undefined);
  const [originalMiniteRequired, setOriginalMiniteRequired] =
    useState<number>();
  const [selectedResources, setSelectedResources] =
    useState<ReservationResourceWithResource[]>();
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    if (!reservationKey) {
      return;
    }
    setLoading(true);
    axios
      .get(
        `${API_END_POINT}/public/ws/${workspaceUid}/shops/${shopUid}/courses/${courseUid}/reservations/${reservationKey}`
      )
      .then((response: AxiosResponse<ResponseBody>) => {
        setCanUpdateReservationDateTimeFromPublic(
          response.data.canUpdateReservationDateTimeFromPublic
        );
        setCanceled(response.data.isCanceled);
        setOriginalDateTime(response.data.originalDateTime);
        setOriginalMiniteRequired(response.data.originalMiniteRequired);
        setSelectedResources(response.data.selectedResources);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [workspaceUid, shopUid, courseUid, reservationKey]);
  return {
    canUpdateReservationDateTimeFromPublic,
    isCanceled,
    originalDateTime,
    originalMiniteRequired,
    isLoadingReservation: loading,
    selectedResources,
  };
}

export async function reserve(
  workspaceUid: string,
  shopUid: string,
  courseUid: string,
  dateTime: DateTime,
  formResponse: FormResponse,
  resourceResponse: ResourceResponse,
  inflowSource: string | null,
  lineUser: LineUserChannelInfo | undefined,
  lineSessionKey: string | undefined
) {
  const data = {
    dateTime,
    formResponse,
    resourceResponse,
    inflowSource: inflowSource || INFLOW_SOURCE_UNKNOWN,
    lineUser,
    lineSessionKey,
  };
  return fetch(
    `${API_END_POINT}/public/ws/${workspaceUid}/shops/${shopUid}/courses/${courseUid}/reservations`,
    {
      method: 'POST',
      body: JSON.stringify(data),
      headers: {
        'Content-Type': 'application/json',
        'X-Customer-UUID': getCustomerUUID(),
      },
    }
  );
}

export async function changeReservation(
  workspaceUid: string,
  shopUid: string,
  courseUid: string,
  dateTime: DateTime,
  reservationKey: string | null,
  resourceResponse: ResourceResponse
) {
  const data = {
    dateTime,
    resourceResponse,
  };
  return fetch(
    `${API_END_POINT}/public/ws/${workspaceUid}/shops/${shopUid}/courses/${courseUid}/reservations/${reservationKey}`,
    {
      method: 'PUT',
      body: JSON.stringify(data),
      headers: {
        'Content-Type': 'application/json',
        'X-Customer-UUID': getCustomerUUID(),
      },
    }
  );
}

export async function cancelReservation(
  workspaceUid: string,
  shopUid: string,
  courseUid: string,
  reservationKey: string | null,
  reason: string
) {
  const data = {
    data: {
      reason,
    },
  };
  return axios.delete(
    `${API_END_POINT}/public/ws/${workspaceUid}/shops/${shopUid}/courses/${courseUid}/reservations/${reservationKey}`,
    data
  );
}
