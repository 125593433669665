const PARAM_INFLOW_SOURCE = '_src';

export function getInflowSource(paramsString: string): string | null {
  return new URLSearchParams(paramsString).get(PARAM_INFLOW_SOURCE);
}

export function setInflowSourceParam(
  inflowSource: string | null,
  baseParamsString?: string
): string {
  if (!inflowSource && !baseParamsString) {
    return '';
  }
  const params = new URLSearchParams(baseParamsString);
  if (inflowSource) {
    params.set(PARAM_INFLOW_SOURCE, inflowSource);
  }
  return '?' + params.toString();
}
