/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';
import chroma from 'chroma-js';
import { useEffect, useRef, useState } from 'react';
import {
  lineBreakToBrAndHtml,
  lineBreakToParagraphAndHtml,
} from '../../../utils/browsers';
import FoldingButton from './folding-button.svg';
import MoreButton from './more-button.svg';

type Props = {
  description: string;
  enableShowButton?: boolean;
  primaryColor: string;
};

export default function Description(props: Props) {
  const { description, enableShowButton, primaryColor } = props;
  const ref = useRef<HTMLParagraphElement>(null);
  const [isShowAll, setIsShowAll] = useState<boolean>(false);
  const [isShowButton, setIsShowButton] = useState<boolean>(false);

  useEffect(() => {
    function handleResize() {
      if (ref.current) {
        if (ref.current.clientHeight < ref.current.scrollHeight) {
          setIsShowButton(true);
        } else {
          setIsShowButton(false);
        }
      }
    }

    window.addEventListener('resize', handleResize);
    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const styles = {
    description: css({
      paddingTop: '16px',
      '& p': {
        fontSize: '14px',
        lineHeight: '21px',
      },
      '& a': {
        color: primaryColor,
      },
      table: {
        padding: '10px 0px',
        th: {
          background: `${chroma(primaryColor).alpha(0.15)}`,
        },
        'th,td': {
          borderTop: `solid 1px ${primaryColor}`,
          borderBottom: `solid 1px ${primaryColor}`,
          padding: '0px 20px',
        },
      },
    }),
    descriptionShort: css({
      '& p': {
        display: '-webkit-box',
        WebkitBoxOrient: 'vertical',
        WebkitLineClamp: 3,
        overflow: 'hidden',
        fontSize: '14px',
        lineHeight: '21px',
        height: '63px',
      },
    }),
    button: css({
      display: 'block',
      border: 'none',
      background: 'none',
      margin: '16px auto 0',
      cursor: 'pointer',
    }),
  };

  const DescriptionShort = () => {
    return (
      <div css={[styles.description, styles.descriptionShort]}>
        <p ref={ref}>{lineBreakToBrAndHtml(description)}</p>
        {isShowButton && (
          <button
            onClick={() => {
              setIsShowAll(true);
            }}
            css={styles.button}
          >
            <img src={MoreButton} alt="詳細を見る" />
          </button>
        )}
      </div>
    );
  };

  const DescriptionAll = () => {
    return (
      <div css={styles.description}>
        <div>{lineBreakToParagraphAndHtml(description)}</div>
        <button
          onClick={() => {
            setIsShowAll(false);
          }}
          css={styles.button}
        >
          <img src={FoldingButton} alt="表示を短くする" />
        </button>
      </div>
    );
  };

  const DescriptionOnly = () => {
    return (
      <div css={styles.description}>
        <div>{lineBreakToParagraphAndHtml(description)}</div>
      </div>
    );
  };

  if (enableShowButton) {
    return <div>{isShowAll ? <DescriptionAll /> : <DescriptionShort />}</div>;
  } else {
    return (
      <div>
        <DescriptionOnly />
      </div>
    );
  }
}
