/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';
import { ChangeEvent } from 'react';
import {
  defaultFieldCondition,
  Option,
  RadioField,
} from '../../../../../core/types/reservation-form-types';
import { FieldErrorMessage } from '../FieldErrorMessage';
import { BaseFormFieldProps } from './BaseFormField';
import { FieldDescription } from './FieldDescription';
import { FieldLabel } from './FieldLabel';
import { buildFieldValidation } from './input-helpers';
import { styles as inputStyles } from './input-styles';
import { styles as radioCheckboxStyles } from './radio-checkbox-styles';

const styles = {
  ...inputStyles,
  ...radioCheckboxStyles,
  clearOptionLabel: css({
    display: 'block',
    width: 'fit-content',
    lineHeight: '100%',
    cursor: 'pointer',
    fontSize: '12px',
    borderRadius: '4px',
    border: '1px solid #333333',
    margin: 12,
    padding: 4,
  }),
  pulldownPaper: css({
    borderRadius: '10px',
    background: '#FFFFFF',
    padding: '20px 0 0',
    marginTop: '10px',
    boxShadow: '0 3px 6px rgba(44,40,40,0.11)',
  }),
  pulldown: css({
    padding: '16px 10px',
    width: '100%',
    fontSize: '16px',
    color: '#2c3f4c',
    border: 'none',
  }),
};

type RadioFormFieldProps = BaseFormFieldProps & {
  field: RadioField;
};

export function RadioFormField(props: RadioFormFieldProps) {
  const {
    field,
    fieldResponse,
    validationContext,
    onChangeValue,
    primaryColor,
  } = props;
  const { errors, register, trigger } = validationContext;
  const fieldValidation = buildFieldValidation(field);

  const customStyles = {
    customRadio: css({
      position: 'relative',
      display: 'inline-block',
      verticalAlign: 'middle',
      width: '20px',
      height: '20px',
      border: '2px solid #A2AAB7',
      borderRadius: '50%',
      '&.checked': {
        borderColor: primaryColor,
        '&::after': {
          content: "''",
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%,-50%)',
          width: '10px',
          height: '10px',
          borderRadius: '50%',
          background: primaryColor,
        },
      },
    }),
  };

  const isChecked = (option: Option) => {
    const { values } = fieldResponse;
    return (
      values.includes(option.text) ||
      values.find(
        (v) =>
          typeof v !== 'string' &&
          option.uid !== undefined &&
          option.uid === v.uid
      ) !== undefined
    );
  };

  const changeSingleValue = (textOrUidValue: string) => {
    let index = fieldResponse.values.indexOf(textOrUidValue);
    if (index === -1) {
      index = fieldResponse.values.findIndex(
        (v) => typeof v !== 'string' && textOrUidValue === v.uid
      );
    }
    if (index === -1) {
      const option = field.options.find(
        (o) => o.uid && o.uid === textOrUidValue
      );
      if (option) {
        onChangeValue?.(field, [{ uid: option.uid }]);
      } else {
        onChangeValue?.(field, [textOrUidValue]);
      }
    } else {
      onChangeValue?.(field, []);
    }
  };

  const handleChangeRadioOrSelect = async (
    e: ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const value = e.target.value;
    changeSingleValue(value);
    await trigger(field.uid);
  };

  const buildOption = (option: Option, index: number) => {
    const id = `${field.uid}_${index}`;
    return (
      <div key={index} style={{ marginTop: index === 0 ? '0' : '8px' }}>
        <input
          id={id}
          name={field.uid}
          type="radio"
          value={option.uid || option.text}
          checked={isChecked(option)}
          onChange={handleChangeRadioOrSelect}
          ref={register(fieldValidation)}
          style={{ display: 'none' }}
        />
        <label htmlFor={id} css={styles.customLabel}>
          <span
            css={customStyles.customRadio}
            className={isChecked(option) ? 'checked' : ''}
          ></span>
          <span css={styles.customLabelText}>{option.text}</span>
        </label>
      </div>
    );
  };

  const ClearOption = () => (
    <div>
      <input
        id={`${field.uid}_clear-option`}
        name={field.uid}
        type="radio"
        value={undefined}
        onChange={handleChangeRadioOrSelect}
        style={{ display: 'none' }}
      />
      <label
        htmlFor={`${field.uid}_clear-option`}
        css={styles.clearOptionLabel}
        style={{ cursor: 'pointer' }}
      >
        選択をクリアする
      </label>
    </div>
  );

  const buildOptions = () => {
    return field.options.map((option, index) => buildOption(option, index));
  };

  const buildPulldown = () => {
    const getValue = () => {
      const value = fieldResponse.values[0];
      return value ? (typeof value === 'string' ? value : value.uid) : '';
    };
    return (
      <div>
        <select
          name={field.uid}
          multiple={false}
          value={getValue()}
          onChange={handleChangeRadioOrSelect}
          ref={register(fieldValidation)}
          css={styles.pulldown}
        >
          <option value="" disabled>
            選択してください
          </option>
          {field.options.map((option) => {
            return (
              <option value={option.uid || option.text} key={option.uid}>
                {option.text}
              </option>
            );
          })}
        </select>
      </div>
    );
  };

  const fieldError = errors[field.uid];

  return (
    <div
      css={[
        field.isShowPulldown ? styles.pulldownPaper : styles.fieldPaper,
        fieldError && styles.inputErrorField,
      ]}
    >
      <div
        css={styles.inputLabel}
        style={{ marginTop: '0', borderBottom: '.5px solid #8898AA' }}
      >
        <div css={styles.questionText}>
          <FieldLabel field={field} />
        </div>
        <FieldErrorMessage field={field} errors={errors} />
      </div>
      <FieldDescription description={field.description} />
      <div style={{ padding: field.isShowPulldown ? '0 12px' : '20px 12px 0' }}>
        {field.isShowPulldown ? buildPulldown() : buildOptions()}
      </div>
      {fieldResponse.values.length > 0 &&
        fieldResponse.values[0] !== '' &&
        !(field.condition || defaultFieldCondition).public.required && (
          <ClearOption />
        )}
    </div>
  );
}
