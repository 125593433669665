import { useEffect, useState } from 'react';
import { ReservationTable } from '../../../core/types/reservation-types';
import { getWeeklyReservationsLength } from '../routes/page-helpers';

/**
 * カレンダーを強制的に開くかどうかを判定するカスタムフック
 * @param widgetMode ウィジェットモードかどうか
 * @param reservationTable 予約枠テーブル
 * @returns {
 * forceOpenCalendar: カレンダーを強制的に開くかどうか。trueの場合はカレンダーを開く
 * }
 */
export const useForceOpenCalendar = (
  widgetMode: boolean,
  reservationTable: ReservationTable | undefined
): {
  forceOpenCalendar: boolean;
} => {
  // カレンダーを強制的に開く
  const [forceOpenCalendar, setForceOpenCalendar] =
    useState<boolean>(widgetMode);
  // 予約可能な日付がない場合はカレンダーを開く
  useEffect(() => {
    if (!reservationTable) return;
    setForceOpenCalendar(
      forceOpenCalendar || getWeeklyReservationsLength(reservationTable) === 0
    );
  }, [forceOpenCalendar, reservationTable]);
  return {
    forceOpenCalendar,
  };
};
