import axios, { AxiosResponse } from 'axios';
import { useEffect, useState } from 'react';
import { ResponseBody } from '../../../frontend-api/src/handlers/public/public-course-form-setting-handler';
import { ResourceGroupWithMember } from '../../../frontend-api/src/interfaces/resource/resource-group';
import { Course } from '../@interfaces/course';
import { CourseSlotSetting } from '../@interfaces/course-slot-setting';
import { CustomerLastInputOverview } from '../@interfaces/customer-last-input';
import { Shop } from '../@interfaces/shop';
import { PublicShopSetting } from '../@interfaces/shop-setting';
import { Workspace } from '../@interfaces/workspace';
import { PublicWorkspaceSetting } from '../@interfaces/workspace-setting';
import { FormSetting } from '../core/types/reservation-form-types';
import { API_END_POINT } from './api';

export default function useCourseFormSetting(
  workspaceUid: string,
  shopUid: string,
  courseUid: string,
  lineSessionKey: string | undefined
) {
  const [workspace, setWorkspace] = useState<Workspace>();
  const [workspaceSetting, setWorkspaceSetting] =
    useState<PublicWorkspaceSetting>();
  const [shop, setShop] = useState<Shop>();
  const [shopSetting, setShopSetting] = useState<PublicShopSetting>();
  const [course, setCourse] = useState<Course>();
  const [formSetting, setFormSetting] = useState<FormSetting>();
  const [formHeader, setFormHeader] = useState<string>();
  const [formFooter, setFormFooter] = useState<string>();
  const [courseSlotSetting, setCourseSlotSetting] =
    useState<CourseSlotSetting>();
  const [groups, setGroups] = useState<ResourceGroupWithMember[]>([]);
  const [customerLastInput, setCustomerLastInput] =
    useState<CustomerLastInputOverview>();
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setLoading(true);
    axios
      .get(
        `${API_END_POINT}/public/ws/${workspaceUid}/shops/${shopUid}/courses/${courseUid}/form-setting`,
        { params: { lineSessionKey } }
      )
      .then((response: AxiosResponse<ResponseBody>) => {
        setWorkspace(response.data.workspace);
        setWorkspaceSetting(response.data.workspaceSetting);
        setShop(response.data.shop);
        setShopSetting(response.data.shopSetting);
        setCourse(response.data.course);
        setFormSetting(response.data.formSetting);
        setFormHeader(response.data.formHeader);
        setFormFooter(response.data.formFooter);
        setCourseSlotSetting(response.data.courseSlotSetting);
        setGroups(response.data.groups);
        setCustomerLastInput(response.data.customerLastInput);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [workspaceUid, shopUid, courseUid, lineSessionKey]);
  return {
    workspace,
    workspaceSetting,
    shop,
    shopSetting,
    course,
    formSetting,
    formHeader,
    formFooter,
    courseSlotSetting,
    groups,
    customerLastInput,
    isLoadingCourseFormSetting: loading,
  };
}
