/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import {
  AnyField,
  createDefaultFieldResponses,
  FormResponse,
} from '../../../core/types/reservation-form-types';
import { FormFields } from '../../form/components/ReservationForm/FormFields';

const styles = {
  container: css`
    padding: 20px;
    max-width: 692px;
    margin: 0 auto;
  `,
};

export default function FormFieldsPreviewPage() {
  const validationContext = useForm();
  const [fields, setFields] = useState<AnyField[]>([]);
  const [formResponse, setFormResponse] = useState<FormResponse>({
    fields: [],
  });

  useEffect(() => {
    window.addEventListener('message', function (e) {
      if (
        process.env.REACT_APP_PREVIEW_MSG_SENDER_ORIGIN === e.origin &&
        e.data?.type === 'updateFormFieldsPreview'
      ) {
        const { data } = e.data;
        // プレビューで項目の変更を表示するための処理
        if (data) {
          setFields((data.fields as AnyField[]) || []);
          const fieldResponses = createDefaultFieldResponses({
            fields: data.fields as AnyField[],
          });
          console.log('fieldResponses', fieldResponses);
          setFormResponse({
            fields: fieldResponses,
          });
        }
      }
    });
  }, []);

  return (
    <div css={styles.container}>
      <FormFields
        fields={fields}
        formResponse={formResponse}
        primaryColor={'#000'}
        validationContext={validationContext}
      />
    </div>
  );
}
