/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import useCourseFormSetting from '../../../api/use-course-form-setting';
import { useCustomDomainAttachedShop } from '../../../api/use-custom-domain-attached-shop';
import useReservation from '../../../api/use-reservation';
import { DomainError, validDomain } from '../../../components/DomainError';
import { Header, Loading } from '../../../components/Shared';
import {
  createDefaultFieldResponses,
  Field,
  FieldResponseValue,
  FormResponse,
} from '../../../core/types/reservation-form-types';
import { ResourceResponse } from '../../../core/types/reservation-resource-types';
import {
  createDateTime,
  DateTimeFormat,
} from '../../../core/types/reservation-types';
import useStorage from '../../../hooks/use-storage';
import { useTrackLocation } from '../../../hooks/use-track-location';
import useUserScript from '../../../hooks/use-user-script';
import { getLineSessionKeyFromParam } from '../../../models/line';
import { DEFAULT_PRIMARY_COLOR } from '../../../models/theme';
import { lineBreakToParagraphAndHtml } from '../../../utils/browsers';
import { sendShowFormEventToWidget } from '../../../utils/widget';
import ReservationForm from '../components/ReservationForm/ReservationForm';
import ReservationThanks from '../components/ReservationThanks';

const styles = {
  maxWidth: css`
    max-width: 700px;
    margin: 0 auto;
  `,
};

type PageParams = {
  workspaceUid: string;
  shopUid: string;
  courseUid: string;
  action: 'form' | 'thanks';
};

export function createDefaultFormResponse(): FormResponse {
  return {
    fields: [],
  };
}

export function createDefaultResourceResponse(): ResourceResponse {
  return {
    resources: [],
  };
}

export function getStorageKey(workspaceUid: string) {
  return `booknow_${workspaceUid}`;
}

export function getResourceStorageKey(workspaceUid: string, courseUid: string) {
  return `booknow_${workspaceUid}_${courseUid}`;
}

export function clearStorage(workspaceUid: string) {
  sessionStorage.removeItem(getStorageKey(workspaceUid));
}

export default function FormPage() {
  useTrackLocation();

  useEffect(() => {
    sendShowFormEventToWidget();
  }, []);

  const { workspaceUid, shopUid, courseUid, action } = useParams<PageParams>();
  const searchParams = new URL(window.location.href).searchParams;
  const widgetMode = searchParams.get('widget') ? true : false;
  const dateParam = searchParams.get('date');
  const timeParam = searchParams.get('time');
  const endTimeParam = searchParams.get('end_time');
  const reservationKeyParam = searchParams.get('reservationKey');
  const dateTime = createDateTime(
    `${dateParam || ''} ${timeParam || ''}` as DateTimeFormat
  );
  const endDateTime = createDateTime(
    `${dateParam || ''} ${endTimeParam || ''}` as DateTimeFormat
  );
  const lineSessionKey = getLineSessionKeyFromParam();

  const [formResponse, setFormResponse] = useStorage(
    getStorageKey(workspaceUid),
    createDefaultFormResponse(),
    'session'
  );

  const {
    workspace,
    workspaceSetting,
    shop,
    shopSetting,
    course,
    formSetting,
    formHeader,
    formFooter,
    courseSlotSetting,
    groups,
    customerLastInput,
    isLoadingCourseFormSetting,
  } = useCourseFormSetting(
    workspaceUid,
    shopUid || '',
    courseUid || '',
    lineSessionKey
  );

  const { data: shopCustomDomain, isLoading: isLoadingShopCustomDomain } =
    useCustomDomainAttachedShop(workspaceUid, shopUid);

  const { originalDateTime } = useReservation(
    workspaceUid,
    shopUid || '',
    courseUid || '',
    reservationKeyParam
  );

  useUserScript(workspaceSetting, shopSetting);
  const primaryColor =
    shopSetting?.primaryColor ||
    workspaceSetting?.primaryColor ||
    DEFAULT_PRIMARY_COLOR;

  useEffect(() => {
    if (!formSetting || formResponse.fields?.length > 0) {
      return;
    }
    // フォームの初期値の設定
    const defaultFieldValues = createDefaultFieldResponses(formSetting);
    setFormResponse({ ...formResponse, fields: defaultFieldValues });
    // formSettingの取得後、初回のみ実行させるために全ての依存は入れない
    // (利用者の入力値がデフォルトでクリアされるのを防ぐため)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formSetting]);

  const handleChangeValue = (field: Field, newValues: FieldResponseValue[]) => {
    handleChangeValues([{ field, newValues }]);
  };

  const handleChangeValues = (
    targetFields: {
      field: Field;
      newValues: FieldResponseValue[];
    }[]
  ) => {
    const { fields } = formResponse;
    const newFieldResponses = [...fields];
    for (const fieldAndValues of targetFields) {
      const { field, newValues } = fieldAndValues;
      const targetIndex = fields.findIndex((f) => f.uid === field.uid);
      if (targetIndex === -1) {
        newFieldResponses.push({
          uid: field.uid,
          values: newValues,
        });
      } else {
        newFieldResponses[targetIndex].values = newValues;
      }
    }
    setFormResponse({ fields: newFieldResponses });
  };

  if (
    !isLoadingCourseFormSetting &&
    !validDomain(workspaceSetting?.domain) &&
    !isLoadingShopCustomDomain &&
    !validDomain(shopCustomDomain?.fqdn ?? null)
  ) {
    return (
      <DomainError
        domain={workspaceSetting?.domain ?? shopCustomDomain?.fqdn}
      />
    );
  }

  const headerContent = (): JSX.Element | null => {
    if (widgetMode) {
      return null;
    }
    return (
      <Header
        logoPath={shopSetting?.logoPath || workspaceSetting?.logoPath}
        shopName={shop?.name || ''}
        primaryColor={primaryColor}
      />
    );
  };

  return isLoadingCourseFormSetting || isLoadingShopCustomDomain ? (
    <div>
      <Loading primaryColor={primaryColor} />
    </div>
  ) : (
    <div
      css={[
        styles.maxWidth,
        css`
          background: #f2f2f2;
          padding-bottom: 56px;
        `,
      ]}
    >
      {headerContent()}
      {action === 'form' ? (
        <div>
          {formHeader && (
            <div
              className="form-message"
              css={css`
                padding: 16px 24px 0;
              `}
            >
              {lineBreakToParagraphAndHtml(formHeader)}
            </div>
          )}
          <ReservationForm
            workspace={workspace}
            workspaceSetting={workspaceSetting}
            shop={shop}
            course={course}
            dateTime={dateTime}
            formSetting={formSetting}
            formHeader={formHeader}
            formFooter={formFooter}
            courseSlotSetting={courseSlotSetting}
            groups={groups}
            formResponse={formResponse}
            primaryColor={primaryColor}
            reservationKey={reservationKeyParam}
            onChangeValue={handleChangeValue}
            onChangeValues={handleChangeValues}
            customerLastInput={customerLastInput}
          />
        </div>
      ) : (
        <ReservationThanks
          workspace={workspace}
          workspaceSetting={workspaceSetting}
          shop={shop}
          course={course}
          dateTime={dateTime}
          endDateTime={endDateTime}
          formSetting={formSetting}
          formResponse={formResponse}
          courseSlotSetting={courseSlotSetting}
          primaryColor={primaryColor}
          reservationKey={reservationKeyParam}
          widgetMode={widgetMode}
          shopSetting={shopSetting}
        />
      )}
    </div>
  );
}
