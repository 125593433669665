/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';
import { sanitize } from '../../../../../utils/browsers';

const styles = {
  questionDescription: css({
    fontSize: '12px',
    paddingLeft: '12px',
    paddingRight: '12px',
    paddingBottom: '8px',
    whiteSpace: 'pre-wrap',
    overflowWrap: 'break-word',
  }),
};

type FieldDescriptionProps = {
  description: string | undefined;
};

export const FieldDescription = ({ description }: FieldDescriptionProps) => {
  if (!description) {
    return null;
  }
  return (
    <p
      css={styles.questionDescription}
      dangerouslySetInnerHTML={{ __html: sanitize(description) }}
    />
  );
};
