import React from 'react';
import { Helmet } from 'react-helmet-async';
import { isNullOrEmpty } from '../utils/objects';

type Props = {
  target?: any;
  children?: React.ReactNode;
};

/**
 * ページが存在しない場合の情報を表示します。
 * このコンポーネントを使用した場合、metaタグにnoindexが設定されます。
 * @param props
 * @returns
 */
export function PageNotFoundInfo(props: Props) {
  const { target, children } = props;
  const nothing = isNullOrEmpty(target);
  if (!nothing) {
    return null;
  }
  return (
    <>
      {children}
      <Helmet>
        <meta name="robots" content="noindex" />
      </Helmet>
    </>
  );
}
